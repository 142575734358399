import React from "react";
import { useFormContext } from "../../context/FormContext";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../config";
import { MdEditNote } from "react-icons/md";
import { FaDeleteLeft } from "react-icons/fa6";

const SelectedList = ({ sltdForm, handleListRemove }) => {
  const {
    newFormId,
    setNewFormId,
    setFormData,
    setIsKanbanBuilder,
    setBuilderType,
    setKanbanDetail,
  } = useFormContext();
  const navigate = useNavigate();

  // method for clicking on edit button
  const handleEdit = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/form/forms/fetch/list?form_id=${newFormId}`
      );
      const data = await response.json();
      if (response.ok) {
        setKanbanDetail(data.kanban_data);
        // setIsKanbanBuilder(true);
        setBuilderType("list");
        setFormData(sltdForm.formbuilder_data);
        setNewFormId(sltdForm.form_builder_id);
        navigate("/form-builder");
      } else {
        console.log("error in editing list view data: ", data);
        // setIsKanbanBuilder(true);
        setBuilderType("list");
        setFormData(sltdForm.formbuilder_data);
        navigate("/form-builder");
      }
    } catch (err) {
      console.error("error: ", err);
    }
  };
  return (
    <div className="selectedForm">
      {sltdForm !== "" && (
        <>
          <span>{sltdForm.form_builder_name}'s Kanban View</span>
          <div className="selectedFormBtns">
            <button className="btn btn-secondary" onClick={handleEdit}>
              <MdEditNote style={{ fontSize: "25px" }} />
            </button>
            <button className="btn btn-danger" onClick={handleListRemove}>
              <FaDeleteLeft style={{ fontSize: "20px" }} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedList;
