import React, { useEffect, useState } from "react";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";
import { CircleLoader } from "react-spinners";
import { RiDeleteBin5Line } from "react-icons/ri";
import EmailTemplateOverlay from "../../template/email_template_modal/EmailTemplateOverlay";
import { CiEdit } from "react-icons/ci";
import { useNodeContext } from "../../context/NodeContext";

const EmailConfigurationPage = () => {
  const {
    processes,
    setProcesses,
    userData,
    emailTemplates,
    setEmailTemplates,
    fetchAllEmailTemplates,
    topRightAlert,
    centerAlert,
    setEmailTemplateId,
    setUpdateEmailTemplate,
    setEmailTemplateModalOpen,
    encryptData,
    newProcessId,
    setNewProcessId,
    fetchAllProcessData,
    centerAlertWithTitle,
    setProcessDetail,
  } = useProcessContext();
  const { setShapeId } = useNodeContext();
  const [loading, setLoading] = useState(true);

  // useEffect for fetching all email templates
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loading spinner
      await fetchAllEmailTemplates(); // Wait for the API call to finish
      setLoading(false); // Hide loading spinner after data is loaded
    };
    fetchData();
  }, [setEmailTemplates, userData.userid]);

  useEffect(() => {
    if (processes.length === 0) {
      const fetchProcesses = async () => await fetchAllProcessData();
      fetchProcesses();
    }
  }, [processes]);

  // method for getting status that email is assined to any processes activity or not
  const handleIsEmailAlreadyUsed = async (emailId) => {
    if (processes.length === 0) {
      return false;
    }

    for (const process of processes) {
      const nodesData = JSON.parse(process.process_data_flow.nodesData);

      const emailNodes = nodesData.filter((node) => node.type === "email");

      const isEmailUsed = emailNodes.some((emailNode) => {
        if (
          emailNode.data.template !== null &&
          emailNode.data.template !== undefined
        ) {
          return emailNode.data.template.template_id === emailId;
        }

        return false;
      });

      if (isEmailUsed) {
        return true;
      }
    }

    return false;
  };

  // method for template data edit
  const handleTemplateDataEdit = (e, templateId) => {
    e.preventDefault();
    setShapeId(null);
    setNewProcessId(null);
    setProcessDetail(null);
    setEmailTemplateId(templateId);
    setUpdateEmailTemplate(true);
    setEmailTemplateModalOpen(true);
  };
  // method for delete email
  const handleDelete = async (tempId) => {
    setLoading(true);
    try {
      const payload = {
        user_id: userData.userid,
        email_id: tempId,
        key: API_KEY,
      };
      console.log("email::::: tempId:: ", tempId);

      const isEmailUsed = await handleIsEmailAlreadyUsed(tempId);
      console.log("email::::: ", isEmailUsed);

      if (isEmailUsed) {
        centerAlertWithTitle(
          "Template Delete Restricted!",
          "Email template is assigned to activity.",
          "error"
        );
        return;
      }
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/email/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("delete process: ", data);
        topRightAlert("success", data.message);
        fetchAllEmailTemplates();
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error deleting process:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error deleting process:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <CircleLoader color="#007485" />
      </div>
    );
  return (
    <div className="donor_dash_right">
      <div className="dashboard_buttons">
        <button
          data-bs-toggle="modal"
          data-bs-target="#emailTemplateModal"
          onClick={() => {
            setNewProcessId(null);
            setProcessDetail(null);
            setShapeId(null);
          }}
        >
          Create Email Template
        </button>
      </div>
      {/* Modal */}
      {/* <GroupModalOverlay fetchAllGroups={fetchAllGroups} /> */}
      <EmailTemplateOverlay />
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">
            List of existing email templates
          </h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Template Id</th>
                  <th>Template Name</th>
                  <th>Email Subject</th>
                  <th>Email Trigger</th>
                  <th>Email Limit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {emailTemplates.length > 0 ? (
                  emailTemplates.map((emailTemp, index) => (
                    <tr key={emailTemp._id}>
                      <th>{index + 1}</th>
                      <td>{emailTemp._id}</td>
                      <td>{emailTemp.template_name}</td>
                      <td>{emailTemp.mail_subject}</td>
                      <td>{`${JSON.parse(emailTemp.mail_trigger).timeCount} ${
                        JSON.parse(emailTemp.mail_trigger).timeFormat
                      }`}</td>
                      {/* <td>{emailTemp.mail_trigger}</td> */}
                      <td>{emailTemp.mail_limit}</td>
                      <td className="td_action_cell">
                        <CiEdit
                          className="dash_table_icon_edit"
                          onClick={(e) =>
                            handleTemplateDataEdit(e, emailTemp._id)
                          }
                        />
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() => handleDelete(emailTemp._id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={userData.userid}>
                    <td colSpan={7} className="text-center ">
                      No existing email templates...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfigurationPage;
