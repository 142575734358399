import { useCallback, useRef, useState } from "react";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  MarkerType,
  MiniMap,
  ReactFlow,
  reconnectEdge,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import "@xyflow/react/dist/base.css";
import { v4 as uuidv4 } from "uuid";
import Reactangle from "./custom_node/Rectangle";
import Circle from "./custom_node/Circle";
import Diamond from "./custom_node/Diamond";
import EndCircleNode from "./custom_node/EndCircleNode";
import DoublyCircle from "./custom_node/DoublyCircle";
import { useNodeContext } from "../../../context/NodeContext";
import DownloadButton from "../../../utility_files/DownloadButton";
import SchedulerShape from "./custom_node/SchedulerShape";
import DiscardCircleNode from "./custom_node/DiscardCircleNode";
import RejectCircleNode from "./custom_node/RejectCircleNode";
import WebFormCircle from "./custom_node/WebFormCircle";
import ButtonEdge from "./custome_edge/ButtonEdge";
import ExceptionNode from "./custom_node/ExceptionNode";
import CustomerNode from "./custom_node/CustomerNode";
import VendorNode from "./custom_node/VendorNode";
import InvoiceNode from "./custom_node/InvoiceNode";
import ExportButton from "../../../utility_files/ExportButton";
import { useProcessContext } from "../../../context/ProcessContext";
import EmailNode from "./custom_node/EmailNode";
import EmailVerifyNode from "./custom_node/EmailVerifyNode";

const nodeTypes = {
  start: Circle,
  webform: WebFormCircle,
  task: Reactangle,
  decision: Diamond,
  intermediate: DoublyCircle,
  scheduler: SchedulerShape,
  discard: DiscardCircleNode,
  reject: RejectCircleNode,
  exception: ExceptionNode,
  end: EndCircleNode,
  email: EmailNode,
  email_verify: EmailVerifyNode,
  customer: CustomerNode,
  vendor: VendorNode,
  invoice: InvoiceNode,
};
const edgeTypes = {
  buttonedge: ButtonEdge,
};

const Board = () => {
  const { processDetail } = useProcessContext();
  const { nodes, setNodes, edges, setEdges } = useNodeContext();
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

 

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();
      const type = e.dataTransfer.getData("activityType/oflow");

      // Check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      // Get the initial value based on the type
      let val;
      switch (type) {
        case "task":
          val = "task";
          break;
        case "start":
          val = "start";
          break;
        case "webform":
          val = "webform";
          break;
        case "decision":
          val = "decision";
          break;
        case "intermediate":
          val = "intermediate";
          break;
        case "scheduler":
          val = "scheduler";
          break;
        case "discard":
          val = "discard";
          break;
        case "reject":
          val = "reject";
          break;
        case "exception":
          val = "exception";
          break;
        case "end":
          val = "complete";
          break;
        case "email":
          val = "email";
          break;
        case "email_verify":
          val = "email verify";
          break;
        case "customer":
          val = "create customer";
          break;
        case "vendor":
          val = "create vendor";
          break;
        case "invoice":
          val = "create invoice";
          break;
        default:
          break;
      }

      // Function to generate a unique node value
      const generateUniqueValue = (baseValue, existingValues) => {
        let newValue = baseValue;
        let suffix = 1;
        while (existingValues.includes(newValue)) {
          newValue = `${baseValue}${suffix}`;
          suffix++;
        }
        return newValue;
      };

      // Get all existing node values
      const existingValues = nodes.map((node) => node.data.value);

      // Generate a unique value for the new node
      const uniqueValue = generateUniqueValue(val, existingValues);

      // Get the position for the new node
      const position = reactFlowInstance.screenToFlowPosition({
        x: e.clientX,
        y: e.clientY,
      });

      // Create the new node
      const newNode = {
        id: uuidv4(),
        type,
        position,
        data: {
          value: uniqueValue,
          group: [],
          action: "",
          form: "",
          isFormSelected: false,
          kanbanData: null,
          prev: null,
          prev_name: null,
          current: null,
          next: null,
          next_name: null,
        },
      };
      if (type === "email") {
        newNode.data.template = null;
      }
      // code for assigning form by default at activity drop
      if (
        !["decision", "email", "email_verify", "webform"].includes(
          newNode.type
        ) &&
        Array.isArray(nodes) &&
        nodes.length > 0
      ) {
        const startNode = nodes.find((node) => node.type === "start");
        if (
          startNode.data.form &&
          startNode.data.isFormSelected &&
          startNode.data.isFormConsent
        ) {
          newNode.data.form = startNode?.data?.form || "";
          newNode.data.isFormSelected =
            startNode?.data?.isFormSelected || false;
        } else {
          newNode.data.form = "";
          newNode.data.isFormSelected = false;
        }
      }

      // Add the new node to the existing nodes
      setNodes((prevShapes) => [...prevShapes, newNode]);
    },
    [reactFlowInstance, nodes]
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            // type: "smoothstep",
            type: "buttonedge",
            markerEnd: { type: MarkerType.ArrowClosed, color: "#198754" },
            style: {
              strokeWidth: 1,
              stroke: "#198754",
            },
          },
          eds
        )
      );

      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === params.source) {
            const targetNode = nds.find((n) => n.id === params.target);
            // if (!["decision", "email_verify"].includes(node.type)) {
            if (!["decision"].includes(node.type)) {
              return {
                ...node,
                data: {
                  ...node.data,
                  next: targetNode?.id || null,
                  next_name: targetNode?.data?.value || null,
                },
              };
            }
          }
          if (node.id === params.target) {
            const sourceNode = nds.find((n) => n.id === params.source);
            return {
              ...node,
              data: {
                ...node.data,
                prev: sourceNode?.id || null,
                prev_name: sourceNode?.data?.value || null,
              },
            };
          }
          return node;
        })
      );
    },
    [setEdges, setNodes]
  );

  return (
    <div className="board">
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        snapToGrid
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onInit={setReactFlowInstance}
        onDrop={onDrop}
        onDragOver={onDragOver}
        fitView
        attributionPosition="bottom-left"
        className="download-image"
      >
        <Background variant="lines" />
        <Controls />
        <MiniMap pannable={true} zoomable={true} zoomStep={5} />
        <DownloadButton />
        <ExportButton flowData={processDetail} />
      </ReactFlow>
    </div>
  );
};

export default Board;
