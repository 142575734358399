import React, { useEffect, useState } from "react";
import { useFormContext } from "../context/FormContext";
import { useNodeContext } from "../context/NodeContext";
import { useProcessContext } from "../context/ProcessContext";

const ExportFormFieldJson = ({ sltdForm }) => {
  const { centerAlert, newProcessId, processDetail } = useProcessContext();
  const { parseFormData, parseFormDataWithoutTableO2m, parseFormTableData } =
    useFormContext();
  const { shapeId, nodes } = useNodeContext();
  // Function to generate the new JSON and trigger download
  const [fieldData, setFieldData] = useState(null);
  const [tableFieldData, setTableFieldData] = useState(null);
  const [processObjData, setProcessObjData] = useState({
    process_id: "",
    process_name: "",
    process_stage: "",
    process_stage_id: "",
  });
  useEffect(() => {
    if (sltdForm.formbuilder_data) {
      const formData = parseFormData(sltdForm.formbuilder_data);
      const tablesData = parseFormTableData(sltdForm.formbuilder_data);
      setFieldData([...formData]);
      setTableFieldData([...tablesData]);
    }
  }, [shapeId, nodes]);
  //   useEffect getting latest process data
  useEffect(() => {
    if (newProcessId) {
      setProcessObjData((prevData) => ({
        ...prevData,
        process_id: newProcessId || "",
      }));
    }
    if (processDetail && processDetail.process_name) {
      setProcessObjData((prevData) => ({
        ...prevData,
        process_name: processDetail?.process_name || "",
      }));
    }
    if (shapeId && nodes) {
      const currentNode = nodes.find((node) => node.id === shapeId);

      setProcessObjData((prevData) => ({
        ...prevData,
        process_stage: currentNode?.data?.value ? currentNode?.data?.value : "",
        process_stage_id: shapeId || "",
      }));
    }
  }, [newProcessId, shapeId]);

  function filterFields(fields, dataArray) {
    // Create a Set to store keys that need to be removed from fields
    const keysToRemove = new Set();

    // Iterate over the array of objects
    dataArray.forEach((dataObject) => {
      // Iterate over the keys of the current object
      Object.values(dataObject).forEach((nestedObject) => {
        Object.keys(nestedObject).forEach((key) => {
          // Add key to the Set if it exists in the fields object
          if (fields.hasOwnProperty(key)) {
            keysToRemove.add(key);
          }
        });
      });
    });

    // Create a new object with keys filtered out
    const filteredFields = Object.keys(fields).reduce((acc, key) => {
      if (!keysToRemove.has(key)) {
        acc[key] = fields[key];
      }
      return acc;
    }, {});
    // const newFilteredFields = filteredFields.filter(
    //   (field) =>
    //     !field.technicalName.endsWith("_status") &&
    //     field.widget !== "email_verify_status"
    // );

    return filteredFields;
    // return newFilteredFields;
  }

  const handleDownload = (e) => {
    e.preventDefault();
    // Create the new JSON object with technicalName keys and blank values
    if (fieldData) {
      const removedEmailStatusFields = fieldData?.filter((field) => {
        if (
          field.technicalName.endsWith("_status") &&
          field.widget === "email_verify_status"
        ) {
          return false;
        }
        return true;
      });
      const removedTotalFields = removedEmailStatusFields?.filter(
        (field) =>
          !field.technicalName.startsWith("x_total_x_o2b_") &&
          field.type !== "float"
      );
      const jsonData = removedTotalFields?.reduce((acc, obj) => {
        acc[obj.technicalName] = ""; // Set blank value
        return acc;
      }, {});

      const newExportableData = {
        processDetail: { ...processObjData },
        fields: filterFields(jsonData, tableFieldData),
        // fields: { ...jsonData },
        // tableFields: tableFieldData,
      };
      if (
        tableFieldData &&
        Array.isArray(tableFieldData) &&
        tableFieldData.length > 0
      ) {
        newExportableData.tableFieldData = tableFieldData;
      }

      // Convert JSON object to a string for downloading
      //   const jsonString = JSON.stringify(jsonData, null, 2);
      const jsonString = JSON.stringify(newExportableData, null, 2);

      // Create a blob and a link to download the file
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement("a");
      a.href = url;
      a.download = "form_data.json"; // File name
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    } else {
      centerAlert("error", "Assign Form first to this activity.");
    }
  };

  console.log("fieldData::::::::::::::::::::::: ", fieldData);
  return (
    <button className="docType_create_btn mt-2" onClick={handleDownload}>
      Export Form Json
    </button>
  );
};

export default ExportFormFieldJson;
