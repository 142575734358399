import React, { useEffect, useState } from "react";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CircleLoader } from "react-spinners";
import GroupModalOverlay from "../../template/group_modal_overlay/GroupModalOverLay";

const Groups = () => {
  const {
    userData,
    groups,
    setGroups,
    fetchAllGroups,
    topRightAlert,
    centerAlert,
    encryptData,
  } = useProcessContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loading spinner
      await fetchAllGroups(); // Wait for the API call to finish
      setLoading(false); // Hide loading spinner after data is loaded
    };
    fetchData();
  }, [setGroups, userData.userid]);

  // method for delete group
  const handleDelete = async (groupId) => {
    try {
      const payload = {
        user_id: userData.userid,
        group_id: groupId,
        key: API_KEY,
      };

      // Encrypt the payload before sending it
      const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/group/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedPayload }),
        // body: JSON.stringify({
        //   user_id: userData.userid,
        //   group_id: groupId,
        //   key: API_KEY,
        // }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("delete process: ", data);
        topRightAlert("success", data.message);
        // Filter out the deleted process from the state
        // setProcesses((prevProcesses) =>
        //   prevProcesses.filter((process) => process.process_id !== processId)
        // );
        fetchAllGroups();
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error deleting process:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error deleting process:", error);
    }
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <CircleLoader color="#007485" />
      </div>
    );

  return (
    <div className="donor_dash_right">
      <div className="dashboard_buttons">
        <button data-bs-toggle="modal" data-bs-target="#groupModal">
          Create User Role
        </button>
      </div>
      {/* Modal */}
      <GroupModalOverlay fetchAllGroups={fetchAllGroups} />
      {/* over modal code */}
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">List of existing user roles</h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Role</th>
                  <th>Internal User Role</th>
                  <th>Section Name</th>
                  <th>Sub User Roles</th>
                  <th>Hutch (database)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {groups.length > 0 ? (
                  groups.map((group, index) => (
                    <tr key={group.group_id}>
                      <th>{index + 1}</th>
                      <td>{group.group_name}</td>
                      <td>
                        {"base."}
                        {group.group_internal}
                      </td>
                      <td>{group.category_name}</td>
                      <td>
                        {Array.isArray(group.sub_group) &&
                        group.sub_group.length > 0
                          ? group.sub_group
                              ?.map((val) => val?.replace("base.", ""))
                              ?.join(", ")
                          : "Not Assigned"}
                      </td>
                      <td>{group.database}</td>
                      <td className="td_action_cell">
                        {/* <CiEdit
                        className="dash_table_icon_edit"
                        onClick={() => handleEdit(process.process_id)}
                      /> */}
                        <RiDeleteBin5Line
                          className="dash_table_icon_del"
                          onClick={() => handleDelete(group._id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={userData.userid}>
                    <td colSpan={7} className="text-center ">
                      No existing groups...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Groups;
