import React, { useEffect } from "react";
import { useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { useFormContext } from "../../context/FormContext";
import CustomDropdown from "../custom_dropdown/CustomDropdown";
import Swal from "sweetalert2";

const DecisionRuleFields = ({
  index,
  condition,
  updateCondition,
  removeCondition,
  formData,
  fieldsObjData,
  setFieldsObjData,
}) => {
  const { parseFormData, parseFormDataWithoutTableO2m } = useFormContext();
  // const operators = [
  //   "=",
  //   "!=",
  //   ">",
  //   "<",
  //   ">=",
  //   "<=",
  //   "in",
  //   "not in",
  //   // "like",
  //   // "ilike",
  // ];
  const [domainFieldValue, setDomainFieldValue] = useState([]);
  // const [fieldsObjData, setFieldsObjData] = useState([]);
  // Get selected field type
  const selectedOperand = fieldsObjData?.find(
    (field) => field.technicalName === condition.field_name
  );
  const operators =
    selectedOperand?.type === "many2many"
      ? ["in", "not in"]
      : ["=", "!=", ">", "<", ">=", "<=", "in", "not in"];

  useEffect(() => {
    // const newFields = parseFormData(formData);
    const newFields = parseFormDataWithoutTableO2m(formData);
    setFieldsObjData([...newFields]);
    if (newFields && Array.isArray(newFields)) {
      let tempField = [];
      newFields.forEach((field) => {
        tempField.push(field.technicalName);
      });
      setDomainFieldValue(tempField);
    }
  }, [formData]);

  // method for render dynamic value fields
  const renderValueField = () => {
    if (selectedOperand) {
      if (selectedOperand.type === "date") {
        return (
          <input
            type="date"
            placeholder="Value"
            value={condition.value}
            onChange={(e) => updateCondition(index, "value", e.target.value)}
            style={{
              width: "85%",
              border: "1px solid #ddd",
              paddingLeft: "10px",
              height: "25px",
              borderRadius: "5px",
              outline: "none",
              color: "#585858",
            }}
          />
        );
      } else if (selectedOperand.type === "selection") {
        return (
          <CustomDropdown
            name="value"
            defaultValue={condition.value || null}
            options={selectedOperand.options?.map((opt) => {
              return {
                value: `${opt.toLowerCase().trim().replace(/ /g, "_")}`,
                label: opt,
              };
            })}
            onChange={(event) =>
              updateCondition(index, "value", event.target.value)
            }
            width={"85%"}
            height={"25px"}
            border="1px solid gray"
            placeholder="value"
          />
        );
      }
    }

    // Default case: return input type="text" for other operand types
    return (
      <>
        <input
          type="text"
          placeholder="Value"
          value={condition.value}
          onChange={(e) => updateCondition(index, "value", e.target.value)}
          style={{
            width: "85%",
            border: "1px solid #ddd",
            paddingLeft: "10px",
            height: "25px",
            borderRadius: "5px",
            outline: "none",
            color: "#585858",
          }}
        />
      </>
    );
  };

  return (
    <div className="domain_condition_box" style={{ width: "100%" }}>
      {index > 0 && (
        <select
          className="domain_condition_logic_operator"
          value={condition.logicOperator}
          onChange={(e) =>
            updateCondition(index, "logicOperator", e.target.value)
          }
        >
          <option value="&">&</option>
          <option value="|">|</option>
        </select>
      )}
      <div className="domain_condition_field" style={{ width: "100%" }}>
        <select
          className="domain_condition_operand"
          value={condition.field_name}
          onChange={(e) => updateCondition(index, "field_name", e.target.value)}
          style={{ width: "85%" }}
        >
          <option value="">Field select</option>
          {fieldsObjData &&
            Array.isArray(fieldsObjData) &&
            fieldsObjData?.map((operand) => (
              <option
                key={`${operand.technicalName}_${operand.type}`}
                value={operand.technicalName}
              >
                {operand.title}
              </option>
            ))}
        </select>
        <select
          className="domain_condition_operator"
          value={condition.operator}
          onChange={(e) => updateCondition(index, "operator", e.target.value)}
        >
          {operators.map((op) => (
            <option key={op} value={op}>
              {op}
            </option>
          ))}
        </select>
        {renderValueField()}

        <button
          onClick={() => removeCondition(index)}
          className="fdu_domain_condition_remove_btn"
        >
          <FaRegTimesCircle />
        </button>
      </div>
    </div>
  );
};

const DecisionRule = ({ ruleName, saveRuleData, form }) => {
  const [fieldsObjData, setFieldsObjData] = useState([]);
  const [conditions, setConditions] = useState([]);

  const addDomainCondition = (e) => {
    e.preventDefault();
    setConditions([
      ...conditions,
      { field_name: "", operator: "=", value: "", logicOperator: "&" },
    ]);
  };

  // old code ====================
  // const updateCondition = (index, key, value) => {
  //   const updatedConditions = conditions.map((condition, idx) =>
  //     idx === index ? { ...condition, [key]: value } : condition
  //   );
  //   setConditions(updatedConditions);
  // };
  // ===============================
  // new code =====================
  const updateCondition = (index, key, value) => {
    const updatedConditions = conditions.map((condition, idx) => {
      if (idx === index) {
        if (key === "field_name") {
          // Get the new field type based on the selected field name
          const selectedField = fieldsObjData.find(
            (field) => field.technicalName === value
          );

          // Determine the new valid operators
          const newOperators =
            selectedField?.type === "many2many"
              ? ["in", "not in"]
              : ["=", "!=", ">", "<", ">=", "<=", "in", "not in"];

          // Update the operator to the first valid operator if the current operator is no longer valid
          const updatedOperator = newOperators.includes(condition.operator)
            ? condition.operator
            : newOperators[0];

          return { ...condition, field_name: value, operator: updatedOperator };
        }

        return { ...condition, [key]: value };
      }

      return condition;
    });

    setConditions(updatedConditions);
  };

  // =================================

  const removeCondition = (index) => {
    const updatedConditions = conditions.filter((_, idx) => idx !== index);
    setConditions(updatedConditions);
  };

  const saveDomain = (e) => {
    e.preventDefault();
    const blankField = conditions.some(
      (condition) => condition.field_name === ""
    );
    if (blankField) {
      return;
    }
    const newDomain = conditions.reduce((acc, condition, idx) => {
      const conditionArray = [
        condition.field_name,
        condition.operator,
        condition.value,
      ];
      if (idx === 0) {
        return acc + "(" + conditionArray + ")";
      } else {
        return acc + condition.logicOperator + "(" + conditionArray + ")";
      }
    }, "");
    const domain = conditions.reduce((acc, condition, index) => {
      const conditionArray = [
        condition.field_name,
        condition.operator,
        condition.value,
      ];
      if (index === 0) {
        return [...acc, conditionArray];
      } else {
        return [...acc, condition.logicOperator, conditionArray];
      }
    }, []);
    // console.log("Generated Domain:", domain);
    console.log("Generated newDomain:", newDomain);
    e.target.name = ruleName || "";
    e.target.value = newDomain || null;
    saveRuleData(e);
    // return domain;
  };
  console.log("conditions:::: ", conditions);

  return (
    <div className="domain_component">
      {conditions.map((condition, index) => (
        <DecisionRuleFields
          key={index}
          index={index}
          condition={condition}
          updateCondition={updateCondition}
          removeCondition={removeCondition}
          formData={form}
          fieldsObjData={fieldsObjData}
          setFieldsObjData={setFieldsObjData}
        />
      ))}
      <button onClick={addDomainCondition} className="domain_condition_btn">
        Add Condition
      </button>
      {conditions.length > 0 && (
        <button onClick={saveDomain} className="domain_condition_btn">
          Save
        </button>
      )}
    </div>
  );
};

export default DecisionRule;
