import React, { useEffect, useState } from "react";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { CircleLoader } from "react-spinners";
import { useProcessContext } from "../../context/ProcessContext";
import { useFormContext } from "../../context/FormContext";
import { Link, useNavigate } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Configurations = () => {
  const {
    topRightAlert,
    userData,
    centerAlert,
    configurations,
    setConfigurations,
    encryptData,
  } = useProcessContext();
  const { setConfigid } = useFormContext();
  const navigate = useNavigate();
  // const [configurations, setConfigurations] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visibleRow, setVisibleRow] = useState(null);

  // fetch all process data
  const fetchAllConfiguration = async () => {
    try {
      const payload = {
        userid: userData.userid,
        configtype: "baseUrl",
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/config/fetch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      if (response.ok) {
        const data = await response.json();
        setConfigurations(data.config);

        // console.log("Data fetched successfully:", data);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Network error: ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllConfiguration();
  }, []);

  // handle for change configuration status
  const configStatusChange = async (configid, newStatus) => {
    try {
      const payload = {
        configid: configid,
        userid: userData.userid,
        status: newStatus,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/config/update/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      const data = await response.json();
      if (response.ok) {
        centerAlert("success", data.message);
        // console.log("status changed Data:::::::: ", data);
        fetchAllConfiguration();
      } else {
        console.error("Error updating status:", response.statusText);
        centerAlert("error", data.message);
      }
    } catch (error) {
      console.error("Network error: ", error);
      centerAlert("error", error);
    }
  };

  // handle for delete configuration
  const configDelete = async (configid) => {
    try {
      const payload = {
        configid: configid,
        userid: userData.userid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/config/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      const data = await response.json();
      if (response.ok) {
        topRightAlert("success", data.message);
        // console.log("status changed Data:::::::: ", data);
        fetchAllConfiguration();
      } else {
        console.error("Error updating status:", response.statusText);
        topRightAlert("error", data.message);
      }
    } catch (error) {
      console.error("Network error: ", error);
      topRightAlert("error", error);
    }
  };

  const configEdit = (e, configid) => {
    e.preventDefault();
    setConfigid(configid);
    navigate("/configuration/update");
  };

  const handleConfigConnect = async (e, configid) => {
    e.preventDefault();
    try {
      const payload = {
        userid: userData.userid,
        configid: configid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/config/connect`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });

      const data = await response.json();
      if (response.ok) {
        window.open(data.redirectUrl, "_blank", "noopener,noreferrer");
        // console.log("Data config connect:", data);
      } else {
        centerAlert("error", data.message);
        console.error("Error fetching data:", data.message);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Network error: ", error);
    }
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <CircleLoader color="#007485" />
      </div>
    );
  return (
    <div className="donor_dash_right">
      <div className="dashboard_buttons">
        <Link to={"/configuration/existing-hutch"}>
          <button>Connect Existing Hutch</button>
        </Link>
        {/* <Link to={"/configuration/new-hutch"}>
          <button>Connect New Hutch</button>
        </Link> */}
      </div>
      <div className="donor_dash_deatil">
        <div className="donor_kit">
          <h2 className="table_caption_head">
            List of existing configurations
          </h2>
          <div className="donor_kit_desc" />
          <div className="donor_table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Configuration Name</th>
                  <th>Configuration Url</th>
                  <th>Hutch (database)</th>
                  <th>Status</th>
                  {/* {showConfigPassword && (
                    <> */}
                  {/* <th>Username</th>
                  <th>Password</th> */}
                  <th>Connect</th>
                  {/* </>
                  )} */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {configurations?.length > 0 ? (
                  configurations?.map((config, index) => (
                    <tr key={config._id}>
                      <th>{index + 1}</th>
                      <td>{config.configname}</td>
                      <td>{config.parameter}</td>
                      <td>{config.database}</td>
                      <td>
                        <div
                          className="form-check form-switch"
                          style={{ paddingLeft: "3.5rem" }}
                        >
                          {config.configname === "Demo" ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={`flexSwitchCheckDefault-${config.isactive}`}
                              checked={config.isactive}
                              style={{
                                cursor: "not-allowed",
                              }}
                              title="Not Allowed"
                              // onChange={(e) =>
                              //   configStatusChange(config._id, e.target.checked)
                              // }
                            />
                          ) : (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={`flexSwitchCheckDefault-${config.isactive}`}
                              checked={config.isactive}
                              onChange={(e) =>
                                configStatusChange(config._id, e.target.checked)
                              }
                            />
                          )}
                        </div>
                      </td>

                      {/* <td>{visibleRow === config._id ? "admin" : "*****"}</td>
                      <td>{visibleRow === config._id ? "admin" : "*****"}</td> */}
                      <td>
                        <button
                          className="configure_connect_button"
                          onClick={(e) => handleConfigConnect(e, config._id)}
                        >
                          Connect
                        </button>
                      </td>

                      <td className="td_action_cell">
                        {config.configname === "Demo" ? (
                          <>
                            <CiEdit
                              style={{
                                cursor: "not-allowed",
                              }}
                              className="dash_table_icon_edit"
                              title="Not Allowed"
                              // onClick={(e) => configEdit(e, config._id)}
                            />
                            <RiDeleteBin5Line
                              style={{
                                cursor: "not-allowed",
                              }}
                              className="dash_table_icon_del"
                              title="Not Allowed"
                              // onClick={() => configDelete(config._id)}
                            />
                          </>
                        ) : (
                          <>
                            <CiEdit
                              style={{
                                cursor: "pointer",
                              }}
                              className="dash_table_icon_edit"
                              onClick={(e) => configEdit(e, config._id)}
                            />
                            <RiDeleteBin5Line
                              style={{
                                cursor: "pointer",
                              }}
                              className="dash_table_icon_del"
                              onClick={() => configDelete(config._id)}
                            />
                          </>
                        )}
                        {/* {visibleRow === config._id ? (
                          <FaEye
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              marginLeft: "3px",
                            }}
                            onClick={() => setVisibleRow(null)}
                          />
                        ) : (
                          <FaEyeSlash
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              marginLeft: "3px",
                            }}
                            onClick={() => setVisibleRow(config._id)}
                          />
                        )} */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={userData.userid}>
                    <td colSpan={8} className="text-center ">
                      No existing configurations...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurations;
