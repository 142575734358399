import React, { createContext, useContext, useEffect, useState } from "react";
import { stringify, parse } from "flatted";
import BASE_URL from "../../config";
import { useProcessContext } from "./ProcessContext";

const FormContext = createContext();

const FormStore = ({ children }) => {
  const { newProcessId } = useProcessContext();
  const safelyParseJSON = (json) => {
    try {
      return parse(json);
    } catch (error) {
      // console.log("Failed to parse JSON:", error, json);
      return null;
    }
  };

  const [forms, setForms] = useState(() => {
    const savedForms = sessionStorage.getItem("forms");
    const parsedForms = safelyParseJSON(savedForms);
    return Array.isArray(parsedForms) ? parsedForms : [];
  });

  const [formData, setFormData] = useState(() => {
    const savedFormData = sessionStorage.getItem("formData");
    const parsedFormData = safelyParseJSON(savedFormData);
    return Array.isArray(parsedFormData) ? parsedFormData : [];
  });

  const [newFormId, setNewFormId] = useState(() => {
    return sessionStorage.getItem("newFormId") || null;
  });

  const [showFieldDetail, setShowFieldDetail] = useState(false);
  const [selectedFieldData, setSelectedFieldData] = useState(null);
  const [selectedFormDetail, setSelectedFormDetail] = useState(null);

  const [kanbanDetail, setKanbanDetail] = useState(() => {
    const savedKanbanData = sessionStorage.getItem("kanbanDetail");
    const parsedKanbanData = safelyParseJSON(savedKanbanData);
    return Array.isArray(parsedKanbanData) ? parsedKanbanData : [];
  });
  const [listDetail, setListDetail] = useState(() => {
    const savedListData = sessionStorage.getItem("listDetail");
    const parsedListData = safelyParseJSON(savedListData);
    return Array.isArray(parsedListData) ? parsedListData : [];
  });

  const [isKanbanBuilder, setIsKanbanBuilder] = useState(() => {
    const savedKanbanBuilder = sessionStorage.getItem("isKanbanBuilder");
    return savedKanbanBuilder ? JSON.parse(savedKanbanBuilder) : false;
  });
  const [builderType, setBuilderType] = useState(() => {
    const savedBuilderType = sessionStorage.getItem("builderType");
    return savedBuilderType ? JSON.parse(savedBuilderType) : "form";
  });

  const [buttonData, setButtonData] = useState(() => {
    const savedButtonData = sessionStorage.getItem("buttonData");
    const parsedButtonData = safelyParseJSON(savedButtonData);
    return parsedButtonData !== null ? parsedButtonData : null;
  });

  const [pythonCode, setPythonCode] = useState(() => {
    const savedPythonCode = sessionStorage.getItem("pythonCode");
    const parsedPythonCode = safelyParseJSON(savedPythonCode);
    return parsedPythonCode !== null ? parsedPythonCode : null;
  });

  const [configid, setConfigid] = useState(null);

  useEffect(() => {
    sessionStorage.setItem("forms", stringify(forms));
  }, [forms]);

  useEffect(() => {
    sessionStorage.setItem("formData", stringify(formData));
  }, [formData]);

  useEffect(() => {
    sessionStorage.setItem("kanbanDetail", stringify(kanbanDetail));
  }, [kanbanDetail]);

  useEffect(() => {
    sessionStorage.setItem("listDetail", stringify(listDetail));
  }, [listDetail]);

  useEffect(() => {
    sessionStorage.setItem("isKanbanBuilder", JSON.stringify(isKanbanBuilder));
  }, [isKanbanBuilder]);

  useEffect(() => {
    sessionStorage.setItem("builderType", JSON.stringify(builderType));
  }, [builderType]);

  useEffect(() => {
    sessionStorage.setItem("buttonData", stringify(buttonData));
  }, [buttonData]);

  useEffect(() => {
    sessionStorage.setItem("pythonCode", stringify(pythonCode));
  }, [pythonCode]);

  useEffect(() => {
    sessionStorage.setItem("newFormId", newFormId);
  }, [newFormId]);

  // method for parsering all nested fields to same level
  const parseFormData = (inputData) => {
    const parsedData = [];

    const extractFields = (data) => {
      if (!Array.isArray(data)) return;

      data.forEach((item) => {
        if (
          ["tab", "remark_history"].includes(item.type) &&
          Array.isArray(item.tabs)
        ) {
          item.tabs.forEach((tab) => {
            if (Array.isArray(tab.content)) {
              tab.content.forEach((page) => extractFields(page));
            }
          });
        } else if (item.type === "group" && Array.isArray(item.fields)) {
          item.fields.forEach((groupRow) => extractFields(groupRow));
        } else if (
          item.technicalName !== "x_o2b_remark_table" &&
          item.type === "table" &&
          Array.isArray(item.columns)
        ) {
          // item.columns.forEach((col) => extractFields(cols));
          // const cols = item.columns?.map((col) => ({
          //   ...col,
          //   technicalName: `${item.tableRelatedField}.${col.technicalName}`,
          // }));

          // extractFields(cols);
          extractFields(item.columns);
          if (item.isTotalField) {
            const totalField = item?.columns?.find(
              (field) => field.technicalName === item.totalfield_field
            );
            const totalTitle = totalField?.title
              ? `Total ${totalField.title}`
              : "Total";
            const totalFieldObj = {
              title: totalTitle,
              // title: `Total ${
              //   item?.columns?.find(
              //     (field) => field.technicalName === item.totalfield_field
              //   )?.title
              // }`,
              technicalName: `x_total_${item.tableRelatedField}`,
              type: "float",
              tooltip: "Total Field",
              isReadOnly: true,
            };
            parsedData.push({ ...totalFieldObj });
          }
        } else if (
          ![
            "label",
            "chatter",
            "separator",
            "ribbon",
            "button",
            "object",
            "action",
            "static_image",
            "group",
            "tab",
            "table",
            "oe_avatar",
            "remark_history",
          ].includes(item.type)
        ) {
          const {
            type,
            title,
            technicalName,
            widget,
            tooltip,
            relatedModel,
            relationField,
            relationTable,
            options,
            isOnChange,
            on_change_relation,
            on_change_relation_model_field,
          } = item;
          parsedData.push({
            type,
            title,
            technicalName,
            widget,
            tooltip,
            relatedModel,
            relationField,
            relationTable,
            options,
            isOnChange,
            on_change_relation,
            on_change_relation_model_field,
          });
          if (widget === "email") {
            parsedData.push({
              type: "boolean",
              title: `${title} Status`,
              technicalName: `${technicalName}_status`,
              widget: "email_verify_status",
            });
          }
        }
      });
    };

    inputData?.forEach(extractFields);

    return parsedData;
  };

  // method for parsering all nested fields to same level without table fields and one2many fields
  const parseFormDataWithoutTableO2m = (inputData) => {
    const parsedData = [];

    const extractFields = (data) => {
      if (!Array.isArray(data)) return;

      data.forEach((item) => {
        if (
          ["tab", "remark_history"].includes(item.type) &&
          Array.isArray(item.tabs)
        ) {
          item.tabs.forEach((tab) => {
            if (Array.isArray(tab.content)) {
              tab.content.forEach((page) => extractFields(page));
            }
          });
        } else if (item.type === "group" && Array.isArray(item.fields)) {
          item.fields.forEach((groupRow) => extractFields(groupRow));
        } else if (
          item.technicalName !== "x_o2b_remark_table" &&
          item.type === "table" &&
          Array.isArray(item.columns)
        ) {
          // extractFields(item.columns);
          if (item.isTotalField) {
            const totalField = item?.columns?.find(
              (field) => field.technicalName === item.totalfield_field
            );
            const totalTitle = totalField?.title
              ? `Total ${totalField.title}`
              : "Total";
            const totalFieldObj = {
              title: totalTitle,
              technicalName: `x_total_${item.tableRelatedField}`,
              type: "float",
              tooltip: "Total Field",
              isReadOnly: true,
            };
            parsedData.push({ ...totalFieldObj });
          }
        } else if (
          ![
            "label",
            "chatter",
            "separator",
            "ribbon",
            "button",
            "object",
            "action",
            "static_image",
            "group",
            "tab",
            "table",
            "one2many",
            "oe_avatar",
            "remark_history",
          ].includes(item.type)
        ) {
          const {
            type,
            title,
            technicalName,
            widget,
            tooltip,
            relatedModel,
            relationField,
            relationTable,
            options,
            isOnChange,
            on_change_relation,
            on_change_relation_model_field,
          } = item;
          parsedData.push({
            type,
            title,
            technicalName,
            widget,
            tooltip,
            relatedModel,
            relationField,
            relationTable,
            options,
            isOnChange,
            on_change_relation,
            on_change_relation_model_field,
          });
          if (widget === "email") {
            parsedData.push({
              type: "boolean",
              title: `${title} Status`,
              technicalName: `${technicalName}_status`,
              widget: "email_verify_status",
            });
          }
        }
      });
    };

    inputData?.forEach(extractFields);

    return parsedData;
  };

  // method for updating related model status
  const handleUpdateRelatedModelStatus = (inputData) => {
    const extractFields = (data) => {
      if (!Array.isArray(data)) return;

      data.forEach((item) => {
        if (
          ["tab", "remark_history"].includes(item.type) &&
          Array.isArray(item.tabs)
        ) {
          item.tabs.forEach((tab) => {
            if (Array.isArray(tab.content)) {
              tab.content.forEach((page) => extractFields(page));
            }
          });
        } else if (item.type === "group" && Array.isArray(item.fields)) {
          item.fields.forEach((groupRow) => extractFields(groupRow));
        } else if (item.type === "table" && Array.isArray(item.columns)) {
          // item.columns.forEach((cols) => extractFields(cols));
          extractFields(item.columns);
        } else if (["many2one", "many2many", "one2many"].includes(item.type)) {
          item.isModelSaved = true;
        }
      });
    };

    inputData?.forEach(extractFields);

    return inputData;
  };

  // method for parsering all nested fields to same level for table
  const parseFormTableData = (inputData) => {
    const parsedData = [];

    const extractFields = (data) => {
      if (!Array.isArray(data)) return;

      data.forEach((item) => {
        if (
          ["tab", "remark_history"].includes(item.type) &&
          Array.isArray(item.tabs)
        ) {
          item.tabs.forEach((tab) => {
            if (Array.isArray(tab.content)) {
              tab.content.forEach((page) => extractFields(page));
            }
          });
        } else if (item.type === "group" && Array.isArray(item.fields)) {
          item.fields.forEach((groupRow) => extractFields(groupRow));
        } else if (
          item.technicalName !== "x_o2b_remark_table" &&
          item.type === "table" &&
          Array.isArray(item.columns)
        ) {
          const fields = item.columns?.reduce((acc, obj) => {
            acc[obj.technicalName] = ""; // Set blank value
            return acc;
          }, {});
          const tableObj = { [item.tableRelatedField]: fields };
          parsedData.push({ ...tableObj });
        }
        // else if (
        //   ![
        //     "label",
        //     "chatter",
        //     "separator",
        //     "ribbon",
        //     "button",
        //     "object",
        //     "action",
        //     "static_image",
        //     "group",
        //     "tab",
        //     "table",
        //     "oe_avatar",
        //     "remark_history",
        //   ].includes(item.type)
        // ) {
        //   const {
        //     type,
        //     title,
        //     technicalName,
        //     widget,
        //     tooltip,
        //     relatedModel,
        //     relationField,
        //     relationTable,
        //     options,
        //   } = item;
        //   parsedData.push({
        //     type,
        //     title,
        //     technicalName,
        //     widget,
        //     tooltip,
        //     relatedModel,
        //     relationField,
        //     relationTable,
        //     options,
        //   });
        // }
      });
    };

    inputData?.forEach(extractFields);

    return parsedData;
  };

  const getMaxTitleLength = (allFields) => {
    if (!Array.isArray(allFields) || allFields.length === 0) {
      return 0;
    }
    let maxLength = 0;
    allFields.forEach((item) => {
      if (item.title && typeof item.title === "string") {
        maxLength = Math.max(maxLength, item.title.length);
      }
    });

    return maxLength;
  };
  // fetching all forms
  const fetchForms = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/form/forms?process_id=${newProcessId}`
      );
      const data = await response.json();
      if (response.ok) {
        setForms([...data.form]);
      } else {
        console.log("error in fetching form: ", data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        setFormData,
        showFieldDetail,
        setShowFieldDetail,
        selectedFieldData,
        setSelectedFieldData,
        forms,
        setForms,
        newFormId,
        setNewFormId,
        selectedFormDetail,
        setSelectedFormDetail,
        kanbanDetail,
        setKanbanDetail,
        isKanbanBuilder,
        setIsKanbanBuilder,
        parseFormData,
        getMaxTitleLength,
        buttonData,
        setButtonData,
        pythonCode,
        setPythonCode,
        configid,
        setConfigid,
        fetchForms,
        handleUpdateRelatedModelStatus,
        parseFormTableData,
        parseFormDataWithoutTableO2m,
        listDetail,
        setListDetail,
        builderType,
        setBuilderType,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormStore;

export const useFormContext = () => useContext(FormContext);
