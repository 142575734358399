import React, { useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../config";
import { useAdminContext } from "./AdminContext";

const ProcessContext = React.createContext();

const ProcessStore = ({ children }) => {
  const { setAdminAuthenticated, setAllUsers } = useAdminContext();
  // Code for Oflow project.............
  const [logoutClicked, setLogoutClicked] = useState(false);
  // const [processes, setProcesses] = useState(() => {
  //   const savedProcesses = sessionStorage.getItem("processes");
  //   return savedProcesses ? JSON.parse(savedProcesses) : [];
  // });
  const [processes, setProcesses] = useState([]);
  const [processDetail, setProcessDetail] = useState(() => {
    const savedProcessDetail = sessionStorage.getItem("processDetail");
    return savedProcessDetail ? JSON.parse(savedProcessDetail) : null;
  });
  const [groups, setGroups] = useState(() => {
    const savedGroups = sessionStorage.getItem("groups");
    return savedGroups ? JSON.parse(savedGroups) : [];
  });
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [configurations, setConfigurations] = useState(null);
  // const [odooGroups, setOdooGroups] = useState(() => {
  //   const savedGroups = sessionStorage.getItem("odooGroups");
  //   return savedGroups ? JSON.parse(savedGroups) : [];
  // });
  const [newProcessId, setNewProcessId] = useState(() => {
    const savedNewProcessId = sessionStorage.getItem("newProcessId");
    return savedNewProcessId ? savedNewProcessId : null;
  });

  const [emailTemplateId, setEmailTemplateId] = useState(() => {
    const savedEmailTemplateId = sessionStorage.getItem("emailTemplateId");
    return savedEmailTemplateId ? savedEmailTemplateId : null;
  });
  const [isUpdateEmailTemplate, setUpdateEmailTemplate] = useState(false);

  const [authenticated, setAuthenticated] = useState(() => {
    const authState = localStorage.getItem("authenticated");
    return authState ? JSON.parse(authState) : false;
  });

  const [userData, setUserData] = useState(() => {
    const userData = localStorage.getItem("userData");
    return userData ? JSON.parse(userData) : null;
  });
  const [docTypes, setDocTypes] = useState(null);
  const [toDos, setTodos] = useState(null);
  const [isEmailTemplateModalOpen, setEmailTemplateModalOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("authenticated", JSON.stringify(authenticated));
  }, [authenticated]);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  // useEffect(() => {
  //   sessionStorage.setItem("processes", JSON.stringify(processes));
  // }, [processes]);

  useEffect(() => {
    // sessionStorage.setItem("processDetail", JSON.stringify(processDetail));
    try {
      sessionStorage.setItem("processDetail", JSON.stringify(processDetail));
    } catch (e) {
      if (e.code === DOMException.QUOTA_EXCEEDED_ERR) {
        console.error("SessionStorage quota exceeded!");
      }
    }
  }, [processDetail]);

  useEffect(() => {
    sessionStorage.setItem("groups", JSON.stringify(groups));
  }, [groups]);

  // useEffect(() => {
  //   sessionStorage.setItem("odooGroups", JSON.stringify(odooGroups));
  // }, [odooGroups]);

  useEffect(() => {
    sessionStorage.setItem("newProcessId", newProcessId);
  }, [newProcessId]);

  useEffect(() => {
    sessionStorage.setItem("emailTemplateId", emailTemplateId);
  }, [emailTemplateId]);

  // method for logout
  const logout = () => {
    setAuthenticated(false);
    setUserData(null);
    setAllUsers([]);
    setProcesses([]);
    setProcessDetail([]);
    setGroups([]);
    setNewProcessId(null);
    setUserData(null);
    localStorage.removeItem("authenticated");
    sessionStorage.removeItem("adminAuthenticated");
    localStorage.removeItem("userData");
    sessionStorage.removeItem("adminData");
    sessionStorage.removeItem("processDetail");
    sessionStorage.removeItem("groups");
    sessionStorage.removeItem("odooGroups");
    sessionStorage.removeItem("newProcessId");
    sessionStorage.removeItem("nodes");
    sessionStorage.removeItem("edges");
    sessionStorage.removeItem("forms");
    sessionStorage.removeItem("formData");
    sessionStorage.removeItem("newFormId");
    sessionStorage.removeItem("shapeId");
    sessionStorage.removeItem("kanbanDetail");
    sessionStorage.removeItem("isKanbanBuilder");
    sessionStorage.removeItem("emailTemplateId");
    sessionStorage.clear();
    localStorage.clear();
    setAuthenticated(false);
    setAdminAuthenticated(false);
  };

  // method for getting one process data
  const processdata = processes.filter(
    (process) => process.process_id === newProcessId
  );

  const centerAlert = (icon, text) => {
    Swal.fire({
      icon,
      text,
    });
  };
  const centerAlertWithTitle = (title, text, icon) => {
    Swal.fire({
      title,
      text,
      icon,
    });
  };

  const topRightAlert = (icon, text) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: icon,
      title: text,
    });
  };

  // method for fetching all active databases
  const fetchAllActiveDatabase = async () => {
    try {
      const payload = {
        userid: userData.userid,
        configtype: "baseUrl",
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/config/fetch/active`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (response.ok) {
        return data.config;
      } else {
        return data;
      }
    } catch (error) {
      console.error("Error fetching processes:", error);
    }
  };

  // method for custom selection field
  const convertToSelectOptions = (arr) => {
    return arr.map((item) => ({
      label: item,
      value: item.toLowerCase().replace(/\s+/g, "_"),
    }));
  };

  // method for fetching all groups
  const fetchAllGroups = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/group/groups/${userData.userid}`
      );
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (Array.isArray(data.groups)) {
        setGroups(data.groups);
      } else {
        setGroups([]);
        throw new Error("No groups are found on user id: ", userData.userid);
      }
    } catch (error) {
      setGroups([]);
      console.error("Error fetching processes:", error);
    } finally {
      // setLoading(false);
    }
  };
  const fetchAllEmailTemplates = async () => {
    try {
      const payload = {
        user_id: userData.userid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/email/fetch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (Array.isArray(data.emails)) {
        setEmailTemplates(data.emails);
      } else {
        setEmailTemplates([]);
        throw new Error("No groups are found on user id: ", userData.userid);
      }
    } catch (error) {
      setEmailTemplates([]);
      console.error("Error fetching processes:", error);
    }
  };

  // fetch all process data
  const fetchAllProcessData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/process/processes/${userData.userid}`
      );
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (Array.isArray(data.processes)) {
        setProcesses(data.processes);
      } else {
        setProcesses([]);
        console.log("error in fetching process data: ", data);
      }
    } catch (error) {
      console.error("Error fetching processes:", error);
      // setError(error);
    }
  };

  // method for fetch configuration value using key
  const fetchConfigurationValue = async (key) => {
    try {
      const payload = {
        key: key,
        apikey: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/configuration/fetch/one`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      if (response.ok) {
        // console.log("response::: fetch key value data: ", data);
        return { isError: false, value: data?.configuration?.value };
      } else {
        console.log("response::: fetch key and value data: ", data);
        return { isError: true, ...data };
      }
    } catch (error) {
      console.log("error: ", error);
      return { isError: true, message: error };
    }
  };

  // Function to encrypt JSON data
  const encryptData = (data, key) => {
    const jsonString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonString, key).toString();
    return encryptedData;
  };

  // Function to decrypt the encrypted JSON data
  const decryptData = (encryptedData, key) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  };

  return (
    <ProcessContext.Provider
      value={{
        userData,
        setUserData,
        authenticated,
        setAuthenticated,
        logoutClicked,
        setLogoutClicked,
        logout,
        processes,
        setProcesses,
        processDetail,
        setProcessDetail,
        groups,
        setGroups,
        emailTemplates,
        setEmailTemplates,
        newProcessId,
        setNewProcessId,
        docTypes,
        setDocTypes,
        toDos,
        setTodos,
        processdata,
        centerAlert,
        centerAlertWithTitle,
        topRightAlert,
        fetchAllActiveDatabase,
        convertToSelectOptions,
        fetchAllGroups,
        fetchAllProcessData,
        configurations,
        setConfigurations,
        encryptData,
        decryptData,
        emailTemplateId,
        setEmailTemplateId,
        isEmailTemplateModalOpen,
        setEmailTemplateModalOpen,
        isUpdateEmailTemplate,
        setUpdateEmailTemplate,
        fetchAllEmailTemplates,
        fetchConfigurationValue,
      }}
    >
      {children}
    </ProcessContext.Provider>
  );
};

export default ProcessStore;

export const useProcessContext = () => useContext(ProcessContext);
