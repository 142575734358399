import React from "react";
import "./SelectedForm.css";
import { MdEditNote } from "react-icons/md";
import { FaDeleteLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "../../context/FormContext";

const SelectedForm = ({ sltdForm, handleFormRemove }) => {
  const {
    setNewFormId,
    setFormData,
    setIsKanbanBuilder,
    setBuilderType,
    setShowFieldDetail,
  } = useFormContext();
  const navigate = useNavigate();
  const handleEdit = () => {
    // setIsKanbanBuilder(false);
    setBuilderType("form");
    setShowFieldDetail(false);
    setFormData(sltdForm.formbuilder_data);
    setNewFormId(sltdForm.form_builder_id);
    navigate("/form-builder");
  };
  // console.log("formdata::: ", sltdForm.formbuilder_data);
  // console.log("formid::: ", sltdForm.form_builder_id);
  return (
    <div className="selectedForm">
      {sltdForm !== "" && (
        <>
          <span>{sltdForm.form_builder_name}</span>
          <div className="selectedFormBtns">
            <button className="btn btn-secondary" onClick={handleEdit}>
              <MdEditNote style={{ fontSize: "25px" }} />
            </button>
            <button
              className="btn btn-danger"
              onClick={() => handleFormRemove(sltdForm.form_builder_id)}
            >
              <FaDeleteLeft style={{ fontSize: "20px" }} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedForm;
