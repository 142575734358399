import React, { memo, useEffect, useState } from "react";
import "./ShapeUpdateForm.css";

import { PiKanban } from "react-icons/pi";
import { BsTable } from "react-icons/bs";
import { RiPagesLine } from "react-icons/ri";

import { useNodeContext } from "../../../context/NodeContext";
import FormBuilderOverlay from "../../../template/form_builder_overlay/FormBuilderOverlay";
import { useFormContext } from "../../../context/FormContext";
import { useProcessContext } from "../../../context/ProcessContext";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../../config";
import SelectedForm from "../../../template/selected_form_ui/SelectedForm";
import {
  MdDelete,
  MdEditNote,
  MdOutlineClose,
  MdOutlineDelete,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import SelectedKanban from "../../../template/selected_form_ui/SelectedKanban";
import { IoMdClose } from "react-icons/io";
import SelectField from "../../../template/selection_field/SelectField";
import { GiCancel } from "react-icons/gi";
import ConditionContainer from "../../../template/condition_fields/ConditionContainer";
import DoctypeForm from "../../../template/doctype_form/DoctypeForm";
import CustomDropdown from "../../../template/custom_dropdown/CustomDropdown";
import CreateCustomer from "./CreateCustomer";
import ActionComponent from "./ActionComponent";
import CreateVendor from "./CreateVendor";
import CreateInvoice from "./CreateInvoice";
import CustomerCreateOverlay from "../../../template/action_modals/CustomerCreateOverlay";
import VendorCreateOverlay from "../../../template/action_modals/VendorCreateOverlay";
import InvoiceCreateOverlay from "../../../template/action_modals/InvoiceCreateOverlay";
import Swal from "sweetalert2";
import { FaDeleteLeft, FaEye, FaEyeSlash } from "react-icons/fa6";
import EmailTemplateOverlay from "../../../template/email_template_modal/EmailTemplateOverlay";
import ExportFormFieldJson from "../../../utility_files/ExportFormFieldJson";
import SelectEmailTemplate from "../../../template/selection_field/SelectEmailTemplate";
import DefaultTab from "./properties_tabs_pages/DefaultTab";
import HtmlEditor from "../../../utility_files/HtmlEditor";
import SelectedList from "../../../template/selected_form_ui/SelectedList";

const ShapeUpdateForm = ({ setCheckInLoading }) => {
  const navigate = useNavigate();
  const {
    nodes,
    setNodes,
    setShowShapeUpdateForm,
    shapeId,
    updateNodeTitle,
    addEdge,
  } = useNodeContext();
  const {
    forms,
    setForms,
    newFormId,
    setKanbanDetail,
    setIsKanbanBuilder,
    setBuilderType,
    fetchForms,
    parseFormData,
    setListDetail,
  } = useFormContext();
  const {
    newProcessId,
    setToDos,
    setDocTypes,
    groups,
    topRightAlert,
    processDetail,
    fetchAllGroups,
    centerAlert,
    emailTemplates,
    setEmailTemplates,
    setEmailTemplateId,
    setEmailTemplateModalOpen,
    setUpdateEmailTemplate,
    fetchAllEmailTemplates,
    encryptData,
    centerAlertWithTitle,
  } = useProcessContext();
  const [conditionModalOpen, setConditionModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState("General");
  const [showApiEnableUrl, setShowApiEnableUrl] = useState(false);
  const [showWebformUrl, setShowWebformUrl] = useState(false);
  const [showThankyouPageEditor, setShowThankyouPageEditor] = useState(false);
  // const [showCustomReferenceInput, setShowCustomReferenceInput] =
  //   useState(false);
  const [thankyouPageContent, setThankyouPageContent] = useState("");
  const [showDocTypeForm, setShowDocTypeForm] = useState(false);
  const [doctypes, setDoctpes] = useState([]);
  // const [customReferenceData, setCustomReferenceData] = useState({
  //   digits: 8,
  //   prefix: "",
  //   suffix: "",
  // });
  const tabs = [
    "General",
    "Default",
    "Views",
    "Template",
    "Advance",
    "Steps",
    "Action",
    "DocTypes",
    "ToDos",
  ];

  const [shapeData, setShapeData] = useState({
    value: "",
    type: "",
    group: [],
    action: "",
    form: "",
    isFormSelected: false,
    kanbanData: null,
    prev: null,
    prev_name: null,
    current: null,
    next: null,
    next_name: null,
    elseNextStep: null,
    isApiEnable: false,
    isDefaultValue: false,
    defaultValue: null,
    doctype: null,
    todos: null,
  });

  // console.log("newFormId: ", newFormId);
  console.log("newProcessId: ", newProcessId);
  // if (newProcessId === 'null') {
  //   navigate("/dashboard/processes");
  // }

  // hard coded doctype object for testing
  // const doctypes = [
  //   {
  //     docType_type: "pdf",
  //     docType_description: "this is portable document format",
  //     isMandatory: true,
  //   },
  //   {
  //     docType_type: "docx",
  //     docType_description: "this is text file",
  //     isMandatory: false,
  //   },
  //   {
  //     docType_type: "pptx",
  //     docType_description: "this is power point file",
  //     isMandatory: true,
  //   },
  // ];

  // Set shape data from the selected node
  useEffect(() => {
    if (shapeId !== null && nodes) {
      const selectedNode = nodes.find((node) => node.id === shapeId);
      console.log("selectedNode::::: ", selectedNode);
      if (selectedNode) {
        const nextsteps = selectedNode.data.next
          ? selectedNode.data.next
          : selectedNode.data.decision_conditions
          ? selectedNode.data.decision_conditions.map((condition) => ({
              condition,
              nextStep: "",
            }))
          : selectedNode.data.next;

        if (["decision", "email", "email_verify"].includes(selectedNode.type)) {
          if (["Views", "Advance"].includes(activeTab)) {
            setActiveTab("General");
          }
        }
        if (selectedNode.type !== "email") {
          if (activeTab === "Template") {
            setActiveTab("General");
          }
        }
        if (selectedNode.type !== "start") {
          if (activeTab === "Advance") {
            setActiveTab("General");
          }
        }
        if (selectedNode.type === "email") {
          shapeData.template = selectedNode.data.template || null;
          shapeData.emailTo = selectedNode.data.emailTo || "";
          setEmailTemplateId(selectedNode?.data?.template?.template_id || null);
        }
        if (["webform"].includes(selectedNode.type)) {
          shapeData.thankyouPage = selectedNode?.data?.thankyouPage || null;
          // shapeData.isCustomReferenceNumber =
          //   selectedNode?.data?.isCustomReferenceNumber || false;
          // if (
          //   selectedNode?.data?.isCustomReferenceNumber &&
          //   selectedNode?.data?.customReferenceNumber
          // ) {
          //   shapeData.customReferenceNumber =
          //     selectedNode?.data?.customReferenceNumber || null;
          //   //   setCustomReferenceData((prevData) => ({
          //   //     ...prevData,
          //   //     digits: selectedNode?.data?.customReferenceNumber?.digits || 8,
          //   //     prefix: selectedNode?.data?.customReferenceNumber.prefix || "",
          //   //     suffix: selectedNode?.data?.customReferenceNumber.suffix || "",
          //   //   }));
          // } else {
          //   shapeData.customReferenceNumber = null;
          // }
        }
        if (selectedNode.type === "webform" || selectedNode.type === "start") {
          shapeData.isAutoNextStep =
            selectedNode?.data?.isAutoNextStep || false;
          shapeData.isCustomReferenceNumber =
            selectedNode?.data?.isCustomReferenceNumber || false;
          if (
            selectedNode?.data?.isCustomReferenceNumber &&
            selectedNode?.data?.customReferenceNumber
          ) {
            shapeData.customReferenceNumber =
              selectedNode?.data?.customReferenceNumber || null;
            //   setCustomReferenceData((prevData) => ({
            //     ...prevData,
            //     digits: selectedNode?.data?.customReferenceNumber?.digits || 8,
            //     prefix: selectedNode?.data?.customReferenceNumber.prefix || "",
            //     suffix: selectedNode?.data?.customReferenceNumber.suffix || "",
            //   }));
          } else {
            shapeData.customReferenceNumber = null;
          }
        }
        if (selectedNode?.type === "email_verify") {
          shapeData.email_verify_fields =
            selectedNode?.data?.email_verify_fields || [];
        }
        setShapeData({
          ...shapeData,
          value: selectedNode.data.value || "",
          type: selectedNode.type || "",
          group: selectedNode.data.group || [],
          action: selectedNode.data.action || "",
          form: selectedNode.data.form || "",
          isFormSelected: selectedNode.data.isFormSelected || false,
          kanbanData: selectedNode.data.kanbanData || null,
          prev: selectedNode.data.prev || null,
          prev_name: selectedNode.data.prev_name || null,
          current: selectedNode.data.current || null,
          next: nextsteps || null,
          next_name: selectedNode.data.next_name || null,
          elseNextStep: selectedNode.data.elseNextStep || null,
          isApiEnable: selectedNode.data.isApiEnable || false,
          isDefaultValue: selectedNode.data.isDefaultValue || false,
          defaultValue: selectedNode.data.defaultValue
            ? selectedNode.data.defaultValue
            : null,
        });
      }
    }
    console.log("shapeData: ", shapeData);
  }, [shapeId, nodes]);

  // Handling input data for shape details
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleStepsChange = (e) => {
    const { name, value } = e.target;
    if (value !== "" || value !== undefined) {
      const stepNode = nodes.find((node) => value === node.id);
      const nodeName = stepNode.data.value;
      const stepKey = `${name}_name`;
      // setShapeData((prevShapeData) => ({
      //   ...prevShapeData,
      //   [name]: value,
      //   [stepKey]: nodeName,
      // }));
      if (name === "prev") {
        const sourceId = value;
        const targetId = shapeId;
        addEdge(sourceId, targetId);
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === sourceId) {
              const targetNode = nds.find((n) => n.id === targetId);
              // if (!["decision", "email_verify"].includes(node.type)) {
              if (!["decision"].includes(node.type)) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    next: targetNode?.id || null,
                    next_name: targetNode?.data?.value || null,
                  },
                };
              }
            }
            if (node.id === shapeId) {
              // const targetNode = nds.find((n) => n.id === shapeId);
              // if (!["decision", "email_verify"].includes(node.type)) {
              // if (!["decision"].includes(node.type)) {
              return {
                ...node,
                data: {
                  ...node.data,
                  [name]: value || null,
                  [stepKey]: nodeName || null,
                },
              };
              // }
            }
            return node;
          })
        );
      }
      if (name === "next") {
        const sourceId = shapeId;
        const targetId = value;
        addEdge(sourceId, targetId);
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === targetId) {
              const sourceNode = nds.find((n) => n.id === sourceId);
              return {
                ...node,
                data: {
                  ...node.data,
                  prev: sourceNode?.id || null,
                  prev_name: sourceNode?.data?.value || null,
                },
              };
            }
            if (node.id === shapeId) {
              // const targetNode = nds.find((n) => n.id === shapeId);
              // if (!["decision", "email_verify"].includes(node.type)) {
              if (!["decision"].includes(node.type)) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    [name]: value || null,
                    [stepKey]: nodeName || null,
                  },
                };
              }
            }
            return node;
          })
        );
      }
      // setShapeData((prevShapeData) => ({
      //   ...prevShapeData,
      //   [name]: value,
      //   [stepKey]: nodeName,
      // }));
    }
  };
  const handleEmailToChange = (e) => {
    if (
      e.target.value !== "" &&
      e.target.value !== null &&
      e.target.value !== undefined
    ) {
      setShapeData((prevData) => ({ ...prevData, emailTo: e.target.value }));
    }
  };
  // method for thankyou page content change
  const handlePageContent = (content) => {
    setThankyouPageContent(content);
  };
  // method for custom reference data number change
  // const handleCustomReferenceData = (e) => {
  //   const { name, value } = e.target;
  //   setCustomReferenceData((prevData) => ({ ...prevData, [name]: value }));
  // };
  // handling email template
  const [emailToFields, setEmailToFields] = useState(null);
  // method for remove template
  const handleTemplateRemove = async (e, templateId) => {
    e.preventDefault();
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId && node.data.template
          ? {
              ...node,
              data: {
                ...node.data,
                ...shapeData,
                template: null,
              },
            }
          : node
      )
    );
    setEmailTemplateId(null);
    topRightAlert("success", "Email template removed from this activity.");
  };
  // method for template data edit
  const handleTemplateDataEdit = (e, templateId) => {
    e.preventDefault();
    setEmailTemplateId(templateId);
    setUpdateEmailTemplate(true);
    setEmailTemplateModalOpen(true);
  };
  // useEffect for getting email field from current model field
  useEffect(() => {
    if (forms && shapeId) {
      const uniqueOperands = new Map();
      forms.forEach((form) => {
        if (form.formbuilder_data) {
          const opr = parseFormData(form.formbuilder_data);
          opr.forEach((op) => {
            if (
              ![
                "static_image",
                "chatter",
                "button",
                "table",
                "tab",
                "group",
                "separator",
                "label",
              ].includes(op.type)
            ) {
              // Add to the map if the technicalName doesn't exist already
              if (!uniqueOperands.has(op.technicalName)) {
                uniqueOperands.set(op.technicalName, op);
              }
            }
          });
        }
      });

      // Convert the map values to an array
      const allformField = [...uniqueOperands.values()];
      const emailField = allformField?.filter(
        (field) =>
          field.widget === "email" ||
          (field.type === "many2one" &&
            ["res.partner", "res.users"].includes(field.relatedModel))
      );
      setEmailToFields([...emailField]);
    }
  }, [shapeId, forms]);

  // method for property tab change
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // shape update form's submit button
  const handleSubmit = () => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? { ...node, data: { ...node.data, ...shapeData } }
          : node
      )
    );
    console.log("shapeData: ", shapeData);
  };

  // useEffect for automate the submit process
  useEffect(() => {
    const intervalId = setInterval(handleSubmit, 2000); // Execute every 2 seconds
    if (conditionModalOpen) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [shapeId, shapeData, setNodes]);

  // method for field blur
  const handleBlurField = (e) => {
    e.preventDefault();
    if (e.target.name === "value") {
      const updatedNodesData = updateNodeTitle(nodes, shapeId, shapeData.value);
      setNodes(updatedNodesData);
    }
    handleSubmit();
  };

  const newFormName = (input) => {
    const nameWithoutNumber = input.replace(/\d+$/, "").trim(); // Remove trailing digits
    const numberMatch = input.match(/(\d+)$/); // Find trailing digits, if any

    let newNumber = numberMatch ? parseInt(numberMatch[1], 10) + 1 : 1;
    return `${nameWithoutNumber} ${newNumber}`;
  };

  // method for handling kanban create
  const handleCreateKanban = () => {
    handleSubmit();
    setKanbanDetail([]);
    // setIsKanbanBuilder(true);
    setBuilderType("kanban");
    navigate("/form-builder");
  };

  // method for handling kanban create
  const handleCreateList = () => {
    handleSubmit();
    // setKanbanDetail([]);
    setListDetail([]);
    // setIsKanbanBuilder(true);
    setBuilderType("list");
    navigate("/form-builder");
  };
  useEffect(() => {
    setForms([]); // Clear forms before fetching new ones
    if (newProcessId) {
      fetchForms();
    }
  }, [newProcessId, setForms]);

  useEffect(() => {
    setEmailTemplates([]); // Clear forms before fetching new ones
    if (newProcessId) {
      fetchAllEmailTemplates();
    }
  }, [newProcessId, setEmailTemplates]);

  const handleFormSelect = (form) => {
    Swal.fire({
      title: "Choose your option",
      text: "Do you want to proceed with the original form or copy form?",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Original Form",
      denyButtonText: "Copy Form",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "swal-confirm",
        denyButton: "swal-deny",
        cancelButton: "swal-cancel",
      },
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setNodes((prevNodes) =>
          prevNodes.map((node) =>
            node.id === shapeId
              ? { ...node, data: { ...node.data, form, isFormSelected: true } }
              : node
          )
        );
        Swal.fire(
          "Original Form Selected",
          "Form assigned successfully.",
          "success"
        );
      } else if (result.isDenied) {
        copyForm(form);
      }
    });
  };

  const copyForm = async (form) => {
    try {
      let response = await tryCreatingForm(
        form,
        `${shapeData.value.replace(" ", "-")} form - copy`
      );

      if (response.ok) {
        const data = await response.json();
        console.log("copy form method response", data);
        assignFormToNode(data.formBuilder);
        Swal.fire("Copy Form Selected", data.message, "success");
      } else {
        const data = await response.json();
        console.log("copy form method response", data);
        centerAlert("error", data.message);
      }
    } catch (error) {
      showErrorAlert(error.message);
      console.error("Error creating copy form:", error);
    }
  };

  const tryCreatingForm = async (form, initialName) => {
    let attempt = 0;
    let name = initialName;
    let response;

    const payload = {
      form_builder_name: name,
      form_builder_detail: form.form_builder_detail,
      formbuilder_data: form.formbuilder_data,
      user_id: form.user_id,
      process_id: form.process_id,
    };

    // Encrypt the payload before sending
    const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);

    do {
      response = await fetch(`${BASE_URL}/form/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedPayload }),
      });

      if (response.status === 409) {
        attempt += 1;
        name = `copy - ${attempt} - ${shapeData.value.trim()} form`;
      }
    } while (response.status === 409);

    return response;
  };

  const assignFormToNode = (form) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? { ...node, data: { ...node.data, form, isFormSelected: true } }
          : node
      )
    );
    setForms((prevForms) => [...prevForms, form]);
  };

  const showErrorAlert = (message) => {
    Swal.fire("Error", message, "error");
  };

  const handleFormRemove = async (formid) => {
    try {
      let formNodeCount = 0;
      nodes.forEach((node) => {
        if (node.data.form && node.data.form.form_builder_id === formid) {
          formNodeCount++;
        }
      });
      if (formNodeCount === 1) {
        const payload = {
          form_builder_id: formid,
          process_id: newProcessId,
        };
        const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);
        const response = await fetch(`${BASE_URL}/form/delete`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: encryptedPayload }),
        });
        if (response.ok) {
          const data = await response.json();
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === shapeId && node.data.form) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    form: "",
                    isFormSelected:
                      node.id === shapeId ? false : node.data.isFormSelected,
                  },
                };
              }
              return node;
            })
          );
          fetchForms();
          console.log("form deleted response: ", data);
          topRightAlert("success", data.message);
        } else {
          const data = await response.json();
          console.log("error in deleting form: ", data.message);
          centerAlert("error", data.message);
        }
      } else {
        setShapeData((prevShapeData) => ({
          ...prevShapeData,
          form: "",
          isFormSelected: false,
        }));
        fetchForms();
        topRightAlert("success", "Form removed from this activity.");
      }
    } catch (error) {
      console.log("error in form delete: ", error);
      centerAlert("error", error);
    }
  };

  const handleDeleteProcessAllForm = async () => {
    await Swal.fire({
      title: "Are You Sure?",
      text: "All forms will be deleted related to this process.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        setShowShapeUpdateForm(false);
        const deleteActivityAllForm = () => {
          setNodes((prevData) => [
            ...prevData.map((node) => ({
              ...node,
              data: { ...node.data, form: "", isFormSelected: false },
            })),
          ]);
        };
        const deleteAllForm = async () => {
          try {
            const payloadData = {
              process_id: newProcessId,
              user_id: processDetail?.user_id || "",
              apikey: API_KEY,
            };
            const encryptedData = encryptData(payloadData, PAYLOAD_SECRET);
            const response = await fetch(`${BASE_URL}/form/delete/all`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ data: encryptedData }),
            });
            const data = await response.json();
            if (response.ok) {
              console.log("response::: delete all form: ", data);
              deleteActivityAllForm();
              await fetchForms();
              centerAlertWithTitle(
                "Forms Deleted Successfully.",
                data.message,
                "success"
              );
            } else {
              console.log("error in delete form", data);
              centerAlertWithTitle(
                "Internal Server Error!",
                data.message,
                "error"
              );
            }
          } catch (error) {
            console.log("error in delete form react: ", error);
            centerAlertWithTitle("Internal Server Error!", error, "error");
          } finally {
            setCheckInLoading(false);
          }
        };

        deleteAllForm();
      }
    });
  };

  const handleEmailTemplateSelect = (emailTemplate) => {
    console.log("email template::: on select email:: ", emailTemplate);
    const data = {
      template_id: emailTemplate._id,
      template_name: emailTemplate.template_name,
      mail_trigger: emailTemplate.mail_trigger,
      mail_limit: emailTemplate.mail_limit,
      mail_to: emailTemplate.mail_to,
      mail_from: emailTemplate.mail_from,
      mail_subject: emailTemplate.mail_subject,
      mail_body: emailTemplate.mail_body,
      attachment_file: [],
    };
    setShapeData((prevData) => {
      return { ...prevData, template: { ...data } };
    });
    Swal.fire(
      "Email Template Selected",
      "Email assigned successfully.",
      "success"
    );
  };

  // handling the kanban remove from node
  const handleKanbanRemove = async () => {
    try {
      const response = await fetch(`${BASE_URL}/form/update/kanban`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          kanban_data: null,
          process_id: newProcessId,
          form_builder_id: newFormId,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (shapeId !== null) {
          topRightAlert("success", data.message);
          setNodes((prevNodes) =>
            prevNodes.map((node) =>
              node.id === shapeId
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      kanbanData: null,
                    },
                  }
                : node
            )
          );
        }
      } else {
        topRightAlert("warning", data.message);
        console.log("error in saving kanban data: ", data);
      }
    } catch (err) {
      topRightAlert("error", err);
      console.error("error: ", err);
    }
    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      kanbanData: null,
    }));
  };
  // handling the kanban remove from node
  const handleListRemove = async () => {
    try {
      const response = await fetch(`${BASE_URL}/form/update/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          list_data: null,
          process_id: newProcessId,
          form_builder_id: newFormId,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (shapeId !== null) {
          topRightAlert("success", data.message);
          setNodes((prevNodes) =>
            prevNodes.map((node) =>
              node.id === shapeId
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      list_data: null,
                    },
                  }
                : node
            )
          );
        }
      } else {
        centerAlert("error", data.message);
        console.log("error in removing list data: ", data);
      }
    } catch (err) {
      topRightAlert("error", err);
      console.error("error: ", err);
    }
    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      list_data: null,
    }));
  };
  // handling the thankyou page add in node
  const handleAddThankyouPage = (e) => {
    e.preventDefault();
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                thankyouPage: thankyouPageContent,
              },
            }
          : node
      )
    );
    setShowThankyouPageEditor(false);

    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      thankyouPage: thankyouPageContent,
    }));
  };

  // handling the thankyou page remove from node
  const handleThankyouPageRemove = (e) => {
    e.preventDefault();
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                thankyouPage: null,
              },
            }
          : node
      )
    );

    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      thankyouPage: null,
    }));
  };

  // handling the custom reference number add in node
  // const handleCustomReferenceSave = (e) => {
  //   e.preventDefault();
  //   setNodes((prevNodes) =>
  //     prevNodes.map((node) =>
  //       node.id === shapeId
  //         ? {
  //             ...node,
  //             data: {
  //               ...node.data,
  //               customReferenceNumber: { ...customReferenceData },
  //             },
  //           }
  //         : node
  //     )
  //   );
  //   setShowCustomReferenceInput(false);

  //   setShapeData((prevShapeData) => ({
  //     ...prevShapeData,
  //     customReferenceNumber: { ...customReferenceData },
  //   }));
  //   setCustomReferenceData((prevData) => ({
  //     ...prevData,
  //     digits: 8,
  //     prefix: "",
  //     suffix: "",
  //   }));
  // };
  // handling the custom reference number remove from node
  // const handleCustomReferenceRemove = (e) => {
  //   e.preventDefault();
  //   setNodes((prevNodes) =>
  //     prevNodes.map((node) =>
  //       node.id === shapeId
  //         ? {
  //             ...node,
  //             data: {
  //               ...node.data,
  //               customReferenceNumber: null,
  //             },
  //           }
  //         : node
  //     )
  //   );
  //   setCustomReferenceData((prevData) => ({
  //     ...prevData,
  //     digits: 8,
  //     prefix: "",
  //     suffix: "",
  //   }));

  //   setShapeData((prevShapeData) => ({
  //     ...prevShapeData,
  //     customReferenceNumber: null,
  //   }));
  // };

  // method for calling handleSubmit method on clicking save button
  const handleSaveClick = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  // method for removing condition
  const handleConditionRemove = (e, conIndex) => {
    e.preventDefault();
    const currentNode = nodes.find((node) => node.id === shapeId);
    const conditions = currentNode.data.next_name.filter(
      (_, index) => index !== conIndex
    );
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: { ...node.data, next_name: [...conditions] },
            }
          : node
      )
    );
  };

  // handlimg the selection of next step for decision activity
  const handleSelectChange = (index, event) => {
    const val = event.target.value;
    const updatedConditions = shapeData.next_name;
    const node = nodes.find((nd) => nd.id === val);
    updatedConditions[index].nextStepId = val;
    updatedConditions[index].nextStep = node.data.value;
    addEdge(shapeId, val);
    setShapeData({ ...shapeData, next_name: updatedConditions });
  };

  // handlimg the selection of exception step for decision activity
  const handleSelectElseNextStepChange = (e) => {
    const val = e.target.value;
    let exceptionStepObj = shapeData.elseNextStep;
    if (exceptionStepObj === null) {
      exceptionStepObj = { nextStepId: "", nextStep: "" };
    }
    const node = nodes.find((nd) => nd.id === val);
    if (val === "") {
      exceptionStepObj = null;
    } else {
      exceptionStepObj.nextStepId = val;
      exceptionStepObj.nextStep = node?.data?.value;
      addEdge(shapeId, val);
    }
    setShapeData({ ...shapeData, elseNextStep: exceptionStepObj });
  };

  // handling group visibilty for fields
  const [showGroupAccessSelect, setShowGroupAccessSelect] = useState(true);
  const [filteredGroup, setFilteredGroup] = useState(null);
  const handleAddNewGroupAccess = (e) => {
    const selectedGroup = e.target.value;
    if (selectedGroup) {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === shapeId
            ? {
                ...node,
                data: {
                  ...node.data,
                  group: [...node.data.group, selectedGroup],
                },
              }
            : node
        )
      );

      setShowGroupAccessSelect(false);
    }
  };

  const handleGroupAccessDelete = (e, index) => {
    e.preventDefault();
    // setShapeData((prevData) => ({
    //   ...prevData,
    //   group: prevData.group.filter((_, i) => i !== index),
    // }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                group: node.data.group.filter((_, i) => i !== index),
              },
            }
          : node
      )
    );
  };

  // useEffect for filter group by database
  useEffect(() => {
    if (groups.length > 0) {
      const grps = groups.filter(
        (group) => group.database_obj === processDetail.database_obj
      );
      const filteredGrps = grps?.filter(
        (group) => group.category_name === processDetail.process_group_category
      );
      setFilteredGroup(filteredGrps || []);
    } else {
      const getGroups = async () => await fetchAllGroups();
      getGroups();
    }
  }, [groups]);

  // method for prev and next drop down selection
  const formatNodesForPrevNextDropdown = () => {
    return nodes
      .filter((node) => node.id !== shapeId) // Exclude current shapeId from options
      .map((node) => ({
        value: node.id, // Use node id as value
        label: node.data.value, // Use node.data.value as label
      }));
  };

  // Handling default tab set default value
  const handleAddDefaultField = (data) => {
    if (
      shapeData.defaultValue === null ||
      shapeData.defaultValue === undefined
    ) {
      setShapeData((prevData) => ({
        ...prevData,
        defaultValue: {
          ...prevData.defaultValue,
          set: [],
        },
      }));
    }
    setShapeData((prevData) => ({
      ...prevData,
      defaultValue: {
        ...prevData.defaultValue,
        set: [...prevData.defaultValue.set, { ...data }],
      },
    }));
  };

  // Handling default tab set default value field
  const handleDefaultValueFieldRemove = (e, index) => {
    e.preventDefault();
    const data = shapeData.defaultValue?.set;
    data.splice(index, 1);
    setShapeData((prevData) => ({
      ...prevData,
      defaultValue: {
        ...prevData.defaultValue,
        set: [...data],
      },
    }));
  };

  // methods for action tab fields
  const [actions, setActions] = useState([]);
  const [availableActions, setAvailableActions] = useState([
    "Create Customer",
    "Create Vendor",
    "Create Invoice",
  ]);
  const handleRemoveAction = (actionToRemove) => {
    setActions(actions.filter((action) => action !== actionToRemove));
    setAvailableActions([...availableActions, actionToRemove]);
  };

  console.log("shapeId: ", shapeId);
  // console.log("formId: ", newFormId);
  // console.log("emailToField::::: ", emailToFields);
  // console.log("allFormsData: ", JSON.stringify(forms));
  // console.log("selectedConditions: ", selectedConditions);

  return (
    <div className="suf_container">
      <form action="" onSubmit={handleSaveClick} className="suf_container_form">
        <button
          className="close_btn"
          onClick={() => setShowShapeUpdateForm(false)}
        >
          <IoMdClose style={{ fontSize: "30px" }} />
        </button>
        <h4>{shapeData?.value ? shapeData.value : "Activity"} Properties</h4>
        {shapeId === null ? (
          <></>
        ) : (
          <>
            <ul className="saf_tab_box">
              {tabs.map((tab) => (
                <li
                  key={tab}
                  className={`${
                    ["Views", "Advance"].includes(tab) &&
                    ["decision", "email", "email_verify"].includes(
                      shapeData.type
                    )
                      ? "d-none"
                      : ""
                  } ${
                    shapeData.type !== "email" && tab === "Template"
                      ? "d-none"
                      : ""
                  } ${
                    shapeData.type !== "start" && tab === "Advance"
                      ? "d-none"
                      : ""
                  }`}
                >
                  {["Action", "DocTypes", "ToDos"].includes(tab) ? (
                    <button
                      className={`saf_tab_button ${
                        activeTab === tab ? "active_tab" : ""
                      }`}
                      title="Coming Soon Feature"
                      style={{ cursor: "not-allowed" }}
                      // onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </button>
                  ) : (
                    <button
                      className={`saf_tab_button ${
                        activeTab === tab ? "active_tab" : ""
                      }`}
                      onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </button>
                  )}
                </li>
              ))}
            </ul>
            <div
              className={`${
                activeTab === "General" ? "saf_tab_general_box" : "d-none"
              } `}
            >
              <div className="suf_inpfield mt-2">
                <label htmlFor="type">Activity Type:</label>
                <input
                  id="type"
                  type="text"
                  name="type"
                  style={{ backgroundColor: "#eee" }}
                  value={shapeData.type}
                  readOnly
                />
              </div>
              <div className="suf_inpfield mt-1">
                <label htmlFor="title">Activity Name:</label>
                <input
                  id="title"
                  type="text"
                  placeholder="Enter title here..."
                  name="value"
                  value={shapeData.value}
                  className="nodrag"
                  onChange={handleInputChange}
                  onBlur={handleBlurField}
                  // readOnly={
                  //   ["decision"].includes(shapeData.type) ? true : false
                  // }
                />
              </div>
              <div className="suf_inpfield mt-1">
                <label htmlFor="group">User Access:</label>
                <div className="suf_group_access_box">
                  {shapeData && shapeData.group.length > 0 && (
                    <ul>
                      {Array.isArray(shapeData.group) &&
                        shapeData.group.map((groupName, index) => (
                          <li key={index} className="suf_group_access_list">
                            <span style={{ fontSize: "14px" }}>
                              {index + 1}.
                            </span>
                            <span className="suf_group_access_name_span">
                              {groupName}
                            </span>
                            <button
                              className="suf_group_access_delete"
                              onClick={(e) => handleGroupAccessDelete(e, index)}
                            >
                              <GiCancel />
                            </button>
                          </li>
                        ))}
                    </ul>
                  )}
                  {showGroupAccessSelect ? (
                    <div className="suf_group_access_select_box">
                      <select
                        name="group"
                        id="group"
                        onChange={handleAddNewGroupAccess}
                        onBlur={handleBlurField}
                      >
                        <option value="">Select user access</option>
                        {filteredGroup?.map((group, index) => (
                          <option
                            key={index}
                            value={`base.${group.group_internal}`}
                          >
                            {group.group_name}
                            {/* {`(base.${group.group_internal})`} */}
                          </option>
                        ))}
                        {/* {groups?.map((group, index) => (
                          <option
                            key={index}
                            value={`base.${group.group_internal}`}
                          >
                            {group.group_name}
                            {`(base.${group.group_internal})`}
                          </option>
                        ))} */}
                      </select>
                      <button
                        className="suf_group_access_close_button"
                        onClick={() => setShowGroupAccessSelect(false)}
                      >
                        <MdOutlineClose />
                      </button>
                    </div>
                  ) : (
                    <>
                      <span
                        onClick={() => setShowGroupAccessSelect(true)}
                        className="suf_group_access_show_select"
                      >
                        Add user access
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${
                activeTab === "Default" ? "saf_tab_doctype_box" : "d-none"
              } `}
            >
              <DefaultTab
                shapeData={shapeData}
                setShapeData={setShapeData}
                handleConditionRemove={handleConditionRemove}
                handleBlurField={handleBlurField}
                handleInputChange={handleInputChange}
                handleAddDefaultField={handleAddDefaultField}
                handleDefaultValueFieldRemove={handleDefaultValueFieldRemove}
              />
            </div>

            <div
              className={`${
                activeTab === "Steps" ? "saf_tab_steps_box" : "d-none"
              } `}
            >
              <div className="suf_inpfield align-items-center mt-1">
                <label className="align-self-start">Activity Steps:</label>
                <div className="suf_inpfield d-none">
                  <label htmlFor="currentStep">Current Step:</label>
                  <input
                    id="currentStep"
                    type="text"
                    name="current"
                    value={shapeData.value}
                    style={{ cursor: "not-allowed" }}
                    className="nodrag"
                    onChange={handleInputChange}
                    onBlur={handleBlurField}
                    readOnly
                  />
                </div>
                {!["start", "webform"].includes(shapeData.type) && (
                  <div className="suf_sub_inpfield">
                    <label htmlFor="prevStep">Previous Step</label>
                    <CustomDropdown
                      name="prev"
                      defaultValue={shapeData.prev || ""}
                      options={formatNodesForPrevNextDropdown()}
                      onChange={(event) => handleStepsChange(event)}
                      onBlur={handleBlurField}
                      width={"90%"}
                      height={"25px"}
                    />
                  </div>
                )}
                {/* code for normal next step */}
                {![
                  // "email_verify",
                  "decision",
                  "exception",
                  "discard",
                  "reject",
                  "end",
                ].includes(shapeData.type) && (
                  <div className="suf_sub_inpfield">
                    <label htmlFor="nextStep">Next Step</label>
                    <CustomDropdown
                      name="next"
                      defaultValue={shapeData.next || ""}
                      options={formatNodesForPrevNextDropdown()}
                      onChange={(event) => handleStepsChange(event)}
                      onBlur={handleBlurField}
                      width={"90%"}
                      height={"25px"}
                    />
                  </div>
                )}
                {shapeData.type === "webform" && (
                  // code for auto next step
                  <div
                    className="fb_field_details_form_group_checkbox_container mt-2"
                    style={{
                      justifyContent: "flex-start",
                      gap: "30px",
                      alignItems: "center",
                    }}
                  >
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="fieldIsAutoNextStep"
                        name="isAutoNextStep"
                        checked={shapeData.isAutoNextStep}
                        onChange={handleInputChange}
                        onBlur={handleBlurField}
                      />
                      <span className="checkmark" />
                    </label>
                    <label
                      htmlFor="fieldIsAutoNextStep"
                      className="label_checkbox"
                    >
                      Auto proceed to next step
                    </label>
                  </div>
                )}
              </div>
              {/* code for multi condition */}
              {shapeData.type === "decision" && (
                <div className="suf_inpfield mt-1">
                  <label className="align-self-start">Conditions:</label>
                  {shapeData.next_name && shapeData.next_name.length > 0 && (
                    <>
                      <div
                        className="ps-3 d-flex flex-column justify-content-center align-content-center gap-2"
                        style={{ width: "95%" }}
                      >
                        {shapeData.next_name.map((conditionData, index) => (
                          <div
                            className="d-flex flex-column justify-content-center align-items-start gap-1 mt-2"
                            key={index}
                            style={{ width: "100%" }}
                          >
                            <div
                              className="d-flex justify-content-between align-items-start"
                              style={{ width: "100%" }}
                            >
                              <div
                                className="d-flex justify-content-start align-items-start gap-2"
                                style={{ width: "100%" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  {index + 1}
                                  {"."}
                                </span>
                                <span
                                  style={{
                                    width: "90%",
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                    lineBreak: "anywhere",
                                    lineClamp: "3",
                                    fontSize: "12px",
                                  }}
                                >
                                  {JSON.stringify(
                                    conditionData.condition,
                                    null,
                                    2
                                  )}
                                </span>
                              </div>
                              <button
                                onClick={(e) => handleConditionRemove(e, index)}
                                className="condition_delete_button"
                              >
                                <MdDelete />
                              </button>
                            </div>
                            <div className="suf_sub_inpfield">
                              <label htmlFor={`next${index + 1}`}>
                                Next Step
                              </label>
                              <select
                                id={`next${index + 1}`}
                                name={`next${index + 1}`}
                                value={
                                  shapeData.next_name
                                    ? shapeData.next_name[index].nextStepId
                                    : ""
                                }
                                onChange={(event) =>
                                  handleSelectChange(index, event)
                                }
                                onBlur={handleBlurField}
                              >
                                <option value="">Select next activity</option>
                                {nodes.map((node) => {
                                  if (node.id !== shapeId) {
                                    return (
                                      <option key={node.id} value={node.id}>
                                        {node.data.value}
                                      </option>
                                    );
                                  }
                                  return null;
                                })}
                              </select>
                            </div>
                          </div>
                        ))}
                        {/* code for exception case field */}
                        <div className="suf_sub_inpfield">
                          <label htmlFor="elseNextStep">Exception Step</label>
                          <select
                            id="elseNextStep"
                            name="elseNextStep"
                            value={shapeData?.elseNextStep?.nextStepId || ""}
                            onChange={handleSelectElseNextStepChange}
                            onBlur={handleBlurField}
                          >
                            <option value="">Select exception activity</option>
                            {nodes.map((node) => {
                              if (node.id !== shapeId) {
                                return (
                                  <option key={node.id} value={node.id}>
                                    {node.data.value}
                                  </option>
                                );
                              }
                              return null;
                            })}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  <ConditionContainer shapeData={shapeData} />
                </div>
              )}
            </div>

            <div
              className={`${
                activeTab === "Views" ? "saf_tab_views_box" : "d-none"
              } ${
                activeTab === "Views" &&
                ["decision", "email", "email_verify"].includes(shapeData.type)
                  ? "d-none"
                  : ""
              } `}
            >
              <div className="suf_inpfield mt-2">
                <label>Assign Form:</label>
                {shapeData.isFormSelected ? (
                  <SelectedForm
                    sltdForm={shapeData.form}
                    handleFormRemove={handleFormRemove}
                  />
                ) : (
                  <SelectField
                    options={
                      forms.length > 0 && shapeData.type !== "webform"
                        ? [
                            { value: "create", label: "Create Form" },
                            { value: "existing", label: "Existing Form" },
                          ]
                        : [{ value: "create", label: "Create Form" }]
                    }
                    forms={forms}
                    handleFormSelect={handleFormSelect}
                    handleBlurField={handleBlurField}
                  />
                )}
              </div>
              {shapeData.isFormSelected && shapeData.type !== "webform" && (
                <>
                  <div className="suf_inpfield mt-1">
                    {shapeData.kanbanData && <label>Assign List:</label>}
                    {shapeData.kanbanData ? (
                      <SelectedList
                        sltdForm={shapeData.form}
                        handleListRemove={handleListRemove}
                      />
                    ) : (
                      <button
                        style={{ cursor: "not-allowed" }}
                        title="Coming soon feature"
                        className="create_kanban_button"
                        // onClick={handleCreateList}
                      >
                        <BsTable style={{ fontSize: "20px" }} /> Create List
                        View
                      </button>
                    )}
                  </div>
                  <div className="suf_inpfield mt-1">
                    {shapeData.kanbanData && <label>Assign Kanban:</label>}
                    {shapeData.kanbanData ? (
                      <SelectedKanban
                        sltdForm={shapeData.form}
                        handleKanbanRemove={handleKanbanRemove}
                      />
                    ) : (
                      <button
                        style={{ cursor: "not-allowed" }}
                        title="Coming soon feature"
                        className="create_kanban_button"
                        // onClick={handleCreateKanban}
                      >
                        <PiKanban style={{ fontSize: "20px" }} /> Create Kanban
                      </button>
                    )}
                  </div>
                </>
              )}
              {Array.isArray(forms) &&
                forms?.length > 0 &&
                shapeData.type === "start" && (
                  <div className="suf_inpfield mt-1">
                    <button
                      className="create_kanban_button"
                      onClick={handleDeleteProcessAllForm}
                    >
                      <MdOutlineDelete style={{ fontSize: "20px" }} /> Delete
                      All Form
                    </button>
                  </div>
                )}
              {shapeData.type === "webform" && shapeData.isFormSelected && (
                <>
                  {/* code for design thank you page */}
                  <div className="suf_inpfield mt-1">
                    {shapeData.thankyouPage && (
                      <label>Assign Thank You Page:</label>
                    )}
                    {shapeData.thankyouPage ? (
                      <>
                        <div className="selectedForm">
                          <span>Thank You Page</span>
                          <div className="selectedFormBtns">
                            <button
                              className="btn btn-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowThankyouPageEditor(true);
                              }}
                            >
                              <MdEditNote style={{ fontSize: "25px" }} />
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={handleThankyouPageRemove}
                            >
                              <FaDeleteLeft style={{ fontSize: "20px" }} />
                            </button>
                          </div>
                        </div>

                        {shapeData.thankyouPage !== "" &&
                          showThankyouPageEditor && (
                            <>
                              <HtmlEditor
                                onContentChange={handlePageContent}
                                initialVal={shapeData.thankyouPage}
                              />
                              <div className="d-flex justify-content-start align-items-center gap-2 mt-1">
                                <button
                                  className="btn btn-secondary"
                                  onClick={handleAddThankyouPage}
                                >
                                  Save Page Data
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowThankyouPageEditor(false);
                                    setThankyouPageContent("");
                                  }}
                                >
                                  Close
                                </button>
                              </div>
                            </>
                          )}
                      </>
                    ) : (
                      <>
                        {showThankyouPageEditor ? (
                          <>
                            <HtmlEditor onContentChange={handlePageContent} />
                            <div className="d-flex justify-content-start align-items-center gap-2 mt-1">
                              <button
                                className="btn btn-secondary"
                                onClick={handleAddThankyouPage}
                              >
                                Save Page Data
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowThankyouPageEditor(false);
                                  setThankyouPageContent("");
                                }}
                              >
                                Close
                              </button>
                            </div>
                          </>
                        ) : (
                          <button
                            // style={{ cursor: "not-allowed" }}
                            title="Coming soon feature"
                            className="create_kanban_button"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowThankyouPageEditor(true);
                              setThankyouPageContent("");
                            }}
                          >
                            <RiPagesLine style={{ fontSize: "20px" }} />{" "}
                            Thank-you Page
                          </button>
                        )}
                      </>
                    )}
                  </div>

                  {/* code for webform sequence generator */}
                  {/* <div
                    className="fb_field_details_form_group_checkbox_container mt-2"
                    style={{
                      justifyContent: "flex-start",
                      gap: "30px",
                      alignItems: "center",
                    }}
                  >
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        id="fieldIsCustomReferenceNumber"
                        name="isCustomReferenceNumber"
                        checked={shapeData.isCustomReferenceNumber}
                        onChange={handleInputChange}
                        onBlur={handleBlurField}
                      />
                      <span className="checkmark" />
                    </label>
                    <label
                      htmlFor="fieldIsCustomReferenceNumber"
                      className="label_checkbox"
                    >
                      Custom Reference Number
                    </label>
                  </div>
                  {shapeData.isCustomReferenceNumber &&
                    shapeData.customReferenceNumber && (
                      <div className="selectedForm" style={{ width: "90%" }}>
                        <div
                          className="d-flex justify-content-start align-items-start gap-2"
                          style={{
                            width: "80%",
                            overflow: "hidden",
                            wordBreak: "break-all",
                            lineBreak: "anywhere",
                          }}
                        >
                          <span>
                            Digits: {shapeData?.customReferenceNumber?.digits},
                          </span>
                          <span>
                            Prefix: {shapeData?.customReferenceNumber?.prefix},
                          </span>
                          <span>
                            Suffix: {shapeData?.customReferenceNumber?.suffix},
                          </span>
                        </div>
                        <div className="selectedFormBtns">
                          <button
                            className="btn btn-secondary"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowCustomReferenceInput(true);
                            }}
                          >
                            <MdEditNote style={{ fontSize: "25px" }} />
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={handleCustomReferenceRemove}
                          >
                            <FaDeleteLeft style={{ fontSize: "20px" }} />
                          </button>
                        </div>
                      </div>
                    )}
                  {!showCustomReferenceInput &&
                  shapeData.isCustomReferenceNumber &&
                  !shapeData.customReferenceNumber ? (
                    <div className="suf_inpfield mt-1">
                      <button
                        className="btn btn-secondary"
                        style={{ fontSize: "14px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowCustomReferenceInput(true);
                        }}
                      >
                        Add Sequence Detail
                      </button>
                    </div>
                  ) : (
                    <>
                      {showCustomReferenceInput && (
                        <div className="suf_inpfield mt-2">
                          <label htmlFor="type">
                            Custome Reference Generate:
                          </label>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="suf_inpfield mt-2">
                              <label htmlFor="digits">Total Digits:</label>
                              <input
                                id="digits"
                                type="number"
                                name="digits"
                                placeholder="Enter total digits"
                                min={2}
                                max={64}
                                style={{ backgroundColor: "#eee" }}
                                value={customReferenceData.digits}
                                onChange={handleCustomReferenceData}
                              />
                            </div>
                            <div className="suf_inpfield mt-2">
                              <label htmlFor="prefix">Prefix:</label>
                              <input
                                id="prefix"
                                type="text"
                                name="prefix"
                                placeholder="Enter prefix"
                                style={{ backgroundColor: "#eee" }}
                                value={customReferenceData.prefix}
                                onChange={handleCustomReferenceData}
                              />
                            </div>
                            <div className="suf_inpfield mt-2">
                              <label htmlFor="suffix">Suffix:</label>
                              <input
                                id="suffix"
                                type="text"
                                name="suffix"
                                placeholder="Enter suffix"
                                style={{ backgroundColor: "#eee" }}
                                value={customReferenceData.suffix}
                                onChange={handleCustomReferenceData}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-start align-items-center gap-2">
                            {" "}
                            <button
                              className="btn btn-secondary d-flex justify-content-center align-items-center"
                              style={{
                                fontSize: "14px",
                                width: "60px",
                                height: "30px",
                              }}
                              onClick={handleCustomReferenceSave}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-secondary d-flex justify-content-center align-items-center"
                              style={{
                                fontSize: "14px",
                                width: "60px",
                                height: "30px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setShowCustomReferenceInput(false);
                                setCustomReferenceData((prevData) => ({
                                  ...prevData,
                                  digits: 8,
                                  prefix: "",
                                  suffix: "",
                                }));
                              }}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )} */}

                  {/* code for webform url */}
                  <div className="suf_inpfield mt-1" style={{ width: "90%" }}>
                    <li className="ms-3" style={{ width: "100%" }}>
                      {showWebformUrl ? (
                        <FaEye
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            marginLeft: "0px",
                            marginRight: "3px",
                          }}
                          onClick={() => setShowWebformUrl(false)}
                        />
                      ) : (
                        <FaEyeSlash
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            marginLeft: "0px",
                            marginRight: "3px",
                          }}
                          onClick={() => setShowWebformUrl(true)}
                        />
                      )}
                      Webform URL:{" "}
                      {showWebformUrl && (
                        <p
                          style={{
                            fontSize: "14px",
                            width: "90%",
                            wordBreak: "break-all",
                            wordWrap: "break-word",
                            lineBreak: "anywhere",
                          }}
                        >{`${
                          processDetail.database_url
                        }/o2b_${processDetail.process_name
                          .toLowerCase()
                          .trim()
                          .replace(/ /g, "_")}/view`}</p>
                      )}
                    </li>
                  </div>
                </>
              )}
            </div>
            <div
              className={`${
                activeTab === "Advance" ? "saf_tab_views_box" : "d-none"
              } ${
                activeTab === "Advance" && !["start"].includes(shapeData.type)
                  ? // ["decision", "email"].includes(shapeData.type)
                    "d-none"
                  : ""
              } `}
            >
              <div className="suf_inpfield mt-1">
                <label htmlFor="isApiEnable">Configure Api</label>
                <div className="fb_field_details_form_group_checkbox_container">
                  <label htmlFor="fieldIsApiEnable" className="label_checkbox">
                    Enable Json Api
                  </label>
                  <label className="checkbox_container">
                    <input
                      type="checkbox"
                      id="fieldIsApiEnable"
                      name="isApiEnable"
                      checked={shapeData.isApiEnable}
                      onChange={handleInputChange}
                      onBlur={handleBlurField}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                {/* code to show api enable content */}
                {shapeData?.isApiEnable && (
                  <>
                    {shapeData.isFormSelected && shapeData.form ? (
                      <>
                        <div
                          className="suf_inpfield mt-1"
                          style={{ width: "90%" }}
                        >
                          <li className="ms-3" style={{ width: "100%" }}>
                            {showApiEnableUrl ? (
                              <FaEye
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  marginLeft: "0px",
                                  marginRight: "3px",
                                }}
                                onClick={() => setShowApiEnableUrl(false)}
                              />
                            ) : (
                              <FaEyeSlash
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  marginLeft: "0px",
                                  marginRight: "3px",
                                }}
                                onClick={() => setShowApiEnableUrl(true)}
                              />
                            )}
                            URL:{" "}
                            {showApiEnableUrl && (
                              <p
                                style={{
                                  fontSize: "14px",
                                  width: "90%",
                                  wordBreak: "break-all",
                                  wordWrap: "break-word",
                                  lineBreak: "anywhere",
                                }}
                              >{`${processDetail.database_url}/post/data`}</p>
                            )}
                          </li>
                          <ExportFormFieldJson sltdForm={shapeData.form} />
                        </div>
                        {/* code for next step move checkbox */}
                        <div
                          className="fb_field_details_form_group_checkbox_container mt-2"
                          style={{
                            justifyContent: "flex-start",
                            gap: "30px",
                            alignItems: "center",
                          }}
                        >
                          <label className="checkbox_container">
                            <input
                              type="checkbox"
                              id="fieldIsAutoNextStep"
                              name="isAutoNextStep"
                              checked={shapeData.isAutoNextStep}
                              onChange={handleInputChange}
                              onBlur={handleBlurField}
                            />
                            <span className="checkmark" />
                          </label>
                          <label
                            htmlFor="fieldIsAutoNextStep"
                            className="label_checkbox"
                          >
                            Auto proceed to next step
                          </label>
                        </div>
                      </>
                    ) : (
                      <label>No Form found on this activity.</label>
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              className={`${
                activeTab === "Template" ? "saf_tab_general_box" : "d-none"
              } `}
            >
              {shapeData?.template !== null ? (
                <>
                  <div className="suf_inpfield mt-1">
                    <label htmlFor="title">Email Template:</label>
                    <div
                      className="d-flex justify-content-between align-items-start"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="d-flex flex-column justify-content-start align-align-items-start gap-1"
                        style={{
                          width: "80%",
                          overflow: "hidden",
                          wordBreak: "break-word",
                          wordWrap: "break-word",
                          lineClamp: "2",
                          textOverflow: "ellipsis",
                          fontSize: "14px",
                        }}
                      >
                        <span>
                          Template Name: {shapeData?.template?.template_name}
                        </span>
                      </div>
                      <div className="d-flex justify-content-center align-items-start gap-2">
                        <button
                          className="template_edit_button"
                          onClick={(e) =>
                            handleTemplateDataEdit(
                              e,
                              shapeData?.template?.template_id
                            )
                          }
                        >
                          <MdEditNote style={{ fontSize: "25px" }} />
                        </button>
                        <button
                          className="template_remove_button"
                          onClick={(e) =>
                            handleTemplateRemove(
                              e,
                              shapeData?.template?.template_id
                            )
                          }
                        >
                          <FaDeleteLeft style={{ fontSize: "20px" }} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="suf_inpfield mt-1">
                    <label htmlFor="emailTo">Email To:</label>
                    <select
                      name="emailTo"
                      id="emailTo"
                      value={shapeData?.emailTo || ""}
                      onChange={handleEmailToChange}
                      onBlur={handleBlurField}
                    >
                      <option value="">Select</option>
                      {emailToFields &&
                        emailToFields?.map((field) => (
                          <option
                            key={`${field.technicalName}-${field.title}`}
                            value={`${field.technicalName}(${field?.relatedModel})`}
                          >
                            {field.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </>
              ) : (
                <>
                  <div className="suf_inpfield mt-2">
                    <label className="">Assign email:</label>
                    <SelectEmailTemplate
                      options={
                        emailTemplates.length > 0
                          ? [
                              {
                                value: "create",
                                label: "Create Email Template",
                              },
                              {
                                value: "existing",
                                label: "Existing Email Template",
                              },
                            ]
                          : [
                              {
                                value: "create",
                                label: "Create Email Template",
                              },
                            ]
                      }
                      emailTemplates={emailTemplates}
                      handleEmailTemplateSelect={handleEmailTemplateSelect}
                      handleBlurField={handleBlurField}
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className={`${
                activeTab === "Action" ? "saf_tab_doctype_box" : "d-none"
              } `}
            >
              {actions.map((action) => {
                if (action === "Create Customer") {
                  return (
                    <CreateCustomer handleRemoveAction={handleRemoveAction} />
                  );
                } else if (action === "Create Vendor") {
                  return (
                    <CreateVendor handleRemoveAction={handleRemoveAction} />
                  );
                } else if (action === "Create Invoice") {
                  return (
                    <CreateInvoice handleRemoveAction={handleRemoveAction} />
                  );
                }
                return null;
              })}

              <ActionComponent
                actions={actions}
                setActions={setActions}
                availableActions={availableActions}
                setAvailableActions={setAvailableActions}
                shapeData={shapeData}
              />
            </div>
            <div
              className={`${
                activeTab === "DocTypes" ? "saf_tab_doctype_box" : "d-none"
              } `}
            >
              {Array.isArray(doctypes) && doctypes?.length > 0 ? (
                <>
                  <div className="suf_inpfield mt-1">
                    <label htmlFor="title">Attach Doctype:</label>
                    <select name="" id="">
                      <option value="">Select doctype</option>
                      {doctypes.map((doctype, index) => (
                        <option key={index} value={doctype.docType_type}>
                          {doctype.docType_type}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : (
                <>
                  {/* {showDocTypeForm ? (
                    <>
                      <DoctypeForm
                        showDocTypeForm={showDocTypeForm}
                        setShowDocTypeForm={setShowDocTypeForm}
                      />
                    </>
                  ) : (
                    <div className="suf_inpfield mt-1">
                      <li className="ms-3">Doctype is not available</li>
                      <button
                        className="docType_create_btn mt-2"
                        onClick={() => setShowDocTypeForm(true)}
                      >
                        Create Doctype
                      </button>
                    </div>
                  )} */}
                </>
              )}
            </div>
          </>
        )}
      </form>
      {/* Modal */}
      <FormBuilderOverlay />
      <EmailTemplateOverlay />
      <CustomerCreateOverlay shapeData={shapeData} />
      <VendorCreateOverlay shapeData={shapeData} />
      <InvoiceCreateOverlay shapeData={shapeData} />
      {/* over modal code */}
    </div>
  );
};

export default memo(ShapeUpdateForm);
