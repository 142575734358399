import React, { createContext, useContext, useEffect, useState } from "react";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../config";
import CryptoJS from "crypto-js";

const AdminContext = createContext();

const AdminStore = ({ children }) => {
  const [adminAuthenticated, setAdminAuthenticated] = useState(() => {
    const authState = sessionStorage.getItem("adminAuthenticated");
    return authState ? JSON.parse(authState) : false;
  });

  const [adminData, setAdminData] = useState(() => {
    const adminData = sessionStorage.getItem("adminData");
    return adminData ? JSON.parse(adminData) : null;
  });
  const [subscriptionId, setsubscriptionId] = useState(() => {
    const subscriptionId = sessionStorage.getItem("subscriptionId");
    return subscriptionId ? JSON.parse(subscriptionId) : null;
  });
  const [allUsers, setAllUsers] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    sessionStorage.setItem(
      "adminAuthenticated",
      JSON.stringify(adminAuthenticated)
    );
  }, [adminAuthenticated]);

  useEffect(() => {
    sessionStorage.setItem("adminData", JSON.stringify(adminData));
  }, [adminData]);
  useEffect(() => {
    sessionStorage.setItem("subscriptionId", JSON.stringify(subscriptionId));
  }, [subscriptionId]);

  // fetch all user data
  const fetchAllUserData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user/all?api_key=${API_KEY}`);
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (Array.isArray(data.users)) {
        setAllUsers(data.users);
      } else {
        setAllUsers([]);
        console.log("error in fetching process data: ", data);
      }
    } catch (error) {
      console.error("Error fetching processes:", error);
      // setError(error);
    }
  };

  // fetch all subscription data
  const fetchAllSubscriptionData = async () => {
    try {
      const requestBody = {
        key: API_KEY,
      };
      const encryptedPayload = encryptData(requestBody, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/subscription/fetch/all`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ data: encryptedPayload }),
      });
      const data = await response.json();
      console.log("Fetched data:", data);

      if (Array.isArray(data.subscriptions)) {
        setSubscriptions(data.subscriptions);
      } else {
        setSubscriptions([]);
        console.log("error in fetching subscription data: ", data);
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  // Function to encrypt JSON data
  const encryptData = (data, key) => {
    const jsonString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonString, key).toString();
    return encryptedData;
  };

  return (
    <AdminContext.Provider
      value={{
        adminAuthenticated,
        setAdminAuthenticated,
        adminData,
        setAdminData,
        allUsers,
        setAllUsers,
        subscriptions,
        setSubscriptions,
        fetchAllUserData,
        fetchAllSubscriptionData,
        subscriptionId,
        setsubscriptionId,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminStore;

export const useAdminContext = () => useContext(AdminContext);
