import React, { useEffect, useState } from "react";
import "./GroupModalOverlay.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../config";
import { useProcessContext } from "../../context/ProcessContext";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { IoMdClose } from "react-icons/io";
import CustomDropdown from "../custom_dropdown/CustomDropdown";

const GroupModalOverlay = ({ fetchAllGroups }) => {
  const {
    groups,
    userData,
    topRightAlert,
    centerAlert,
    fetchAllActiveDatabase,
    encryptData,
  } = useProcessContext();
  const [activeDataBases, setActiveDatabases] = useState(null);
  const [odooGroups, setOdooGroups] = useState([]);
  const animatedComponents = makeAnimated();
  const [groupData, setGroupData] = useState({
    group_database: null,
    group_type: "new",
    // parent_group: null,
    group_name: "",
    group_id: null,
    category_name: "",
    category_id: null,
    sub_group: null,
  });
  const [groupsActiveDb, setGroupsActiveDb] = useState(null);
  const [filteredGroup, setFilteredGroup] = useState(null);
  // method for getting active database
  const getData = async () => {
    const activeDatabases = await fetchAllActiveDatabase();
    // console.log("Active Databases:", activeDatabases);
    setActiveDatabases(activeDatabases);
  };
  // fetching active database
  useEffect(() => {
    getData();
  }, []);
  // fetch odoo existing groups
  const fetchGroups = async (dbname) => {
    try {
      const response = await fetch(
        `${BASE_URL}/odoo/groups?database=${
          dbname || groupData.group_database
        }&api_key=${API_KEY}`
      );
      const data = await response.json();
      if (response.ok) {
        if (Array.isArray(data.data)) {
          setOdooGroups(data.data);
        }
      } else {
        console.error("Error in fetch: ", data);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  useEffect(() => {
    if (groupData.group_database !== "") {
      fetchGroups();
    }
  }, [setOdooGroups, groups]);

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "group_database") {
      fetchGroups(value);
    }
    setGroupData({ ...groupData, [name]: value });
  };

  const handleSelectChange = (event) => {
    const selectedGroupId = event.target.value;
    const selectedGroupName =
      event.target.options[event.target.selectedIndex].text;
    setGroupData({
      ...groupData,
      group_id: selectedGroupId,
      group_name: selectedGroupName,
    });
  };

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    const selectedCategoryName =
      event.target.options[event.target.selectedIndex].text;
    setGroupData({
      ...groupData,
      category_id: selectedCategoryId,
      category_name: selectedCategoryName,
    });
  };

  const handleResetField = () =>
    setGroupData({
      group_database: null,
      group_type: "new",
      group_id: null,
      group_name: "",
      category_id: null,
      category_name: "",
      sub_group: null,
    });

  const createGroup = async () => {
    try {
      const encryptedPayload = encryptData(
        { ...groupData, user_id: userData.userid },
        PAYLOAD_SECRET
      );
      const response = await fetch(`${BASE_URL}/group/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ encryptedData: encryptedPayload }),
        // body: JSON.stringify({ ...groupData, user_id: userData.userid }),
      });
      const data = await response.json();
      // console.log("group create data: ", data);
      if (response.ok) {
        topRightAlert("success", data.message);
        fetchAllGroups(); // Fetch the latest groups after creating a new one
      } else {
        centerAlert("error", data.message);
        console.error("Error in fetch: ", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.error("Error: ", error);
    }
  };

  const handleAddMore = (e) => {
    e.preventDefault();
    if (groupData.group_database === null || groupData.group_database === "") {
      return centerAlert("error", "Hutch field is mandatory to fill.");
    }
    if (groupData.group_type === null || groupData.group_type === "") {
      return centerAlert("error", "Group type field is mandatory to fill.");
    }
    // console.log("groupData: ", groupData);
    createGroup();
    handleResetField();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (groupData.group_database === null || groupData.group_database === "") {
      return centerAlert("error", "Hutch field is mandatory to fill.");
    }
    if (groupData.group_type === null || groupData.group_type === "") {
      return centerAlert("error", "Group type field is mandatory to fill.");
    }
    // console.log("groupData handle submit: ", groupData);
    createGroup();
    handleResetField();
    const modalElement = document.getElementById("groupModal");
    const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  const uniqueCategories = Array.from(
    new Set(odooGroups.map((group) => group.category_name))
  ).filter((name) => name);

  const filteredGroups = odooGroups.filter(
    (group) => group.category_id === parseInt(groupData.category_id)
  );

  // fetching all groups available in specific database
  useEffect(() => {
    if (groupData.group_database) {
      if (groups.length > 0) {
        const grps = groups.filter(
          (group) => group.database_obj === groupData.group_database
        );
        // ========================
        // const uniqueCategories = Array.from(
        //   new Set(grps.map((group) => group.category_name))
        // ).filter((name) => name);
        // setFilteredCategory(uniqueCategories);
        if (groupData.category_name) {
          const allFilteredGrpsByCategory = groups.filter(
            (group) =>
              group.database_obj === groupData.group_database &&
              group.category_name === groupData.category_name
          );
          setFilteredGroup(allFilteredGrpsByCategory);
        }
        // ========================
        setGroupsActiveDb(grps);
      } else {
        fetchAllGroups();
      }
    }
  }, [groupData.group_database, groupData.category_name, groups]);

  // console.log("user_id::::::: ", userData.userid);
  // console.log("groupData::::::: ", groupData);
  return (
    <div
      className="modal fade"
      id="groupModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="groupModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="groupModalLabel">
              Create Group
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="groupModalForm">
                <div className="groupModalForm_inputField">
                  <label htmlFor="group_database">Hutch (database)*</label>
                  <CustomDropdown
                    name="group_database"
                    defaultValue={groupData.group_database || null}
                    options={activeDataBases?.map((db) => {
                      return {
                        value: db._id,
                        label: `${db.configname} (${db.database})`,
                      };
                    })}
                    onChange={(event) => handleDataChange(event)}
                    width={"95%"}
                    height={"30px"}
                    border="1px solid gray"
                  />
                </div>
                {groupData.group_type === "new" && (
                  <>
                    <div className="groupModalForm_inputField">
                      <label htmlFor="new_group_name">User Role*</label>
                      <input
                        type="text"
                        name="group_name"
                        id="new_group_name"
                        placeholder="Enter new user role"
                        value={groupData.group_name}
                        onChange={handleDataChange}
                        required
                      />
                    </div>
                    <div className="groupModalForm_inputField">
                      <label htmlFor="new_category_name">Section</label>
                      <input
                        list="categories"
                        name="category_name"
                        id="new_category_name"
                        placeholder="Enter section name"
                        value={groupData.category_name}
                        onChange={handleDataChange}
                      />
                      <datalist id="categories">
                        {uniqueCategories.map((category, index) => (
                          <option key={index} value={category} />
                        ))}
                      </datalist>
                    </div>
                    <div className="groupModalForm_inputField">
                      <label htmlFor="sub_group">Sub User Role</label>
                      <Select
                        name="sub_group"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={
                          groupData.sub_group
                            ? groupData.sub_group.map((value) => ({
                                value,
                                label:
                                  groupsActiveDb.find(
                                    (group) =>
                                      `base.${group.group_internal}` === value
                                  )?.group_name || value,
                              }))
                            : []
                        }
                        isMulti
                        // options={groupsActiveDb?.map((group) => {
                        options={filteredGroup?.map((group) => {
                          return {
                            value: `base.${group.group_internal}`,
                            label: group.group_name,
                          };
                        })}
                        styles={{
                          container: (base) => ({
                            ...base,
                            width: "100%",
                            // minHeight: "30px",
                          }),
                          control: (base, state) => ({
                            ...base,
                            width: "95%",
                            // minHeight: "25px", // Ensures control height is 25px when no value is selected
                            height: state.hasValue ? "auto" : "25px", // Dynamically increase height if values are selected
                            border: "1px solid gray",
                            padding: "0 5px", // Adjust padding for the placeholder and selected items
                            overflow: "hidden", // Prevent overflow
                            display: "flex",
                            alignItems: "center",
                          }),
                          valueContainer: (base) => ({
                            ...base,
                            padding: "0px 6px", // Minimal padding to avoid overflow
                            minHeight: "25px", // Ensures the container has a minimum height
                            display: "flex", // Flexbox layout to handle wrapping of multiple values
                            alignItems: "center", // Center-align items vertically
                          }),
                          placeholder: (base) => ({
                            ...base,
                            whiteSpace: "nowrap", // Prevent placeholder text from wrapping
                            overflow: "hidden", // Hide overflow text
                            textOverflow: "ellipsis", // Show ellipsis for overflowed text
                          }),
                          menu: (base) => ({
                            ...base,
                            width: "100%",
                            borderRadius: "5px",
                            zIndex: 9999, // Ensure it renders above other components
                          }),
                        }}
                        onChange={(selectedOptions) => {
                          const selectedGroups = selectedOptions.map(
                            (option) => option.value
                          );
                          // console.log("Selected groups:", selectedGroups);

                          setGroupData({
                            ...groupData,
                            sub_group: selectedGroups, // Save the selected values to groupData
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button className="btn modal_save_button" onClick={handleAddMore}>
                Add more
              </button>
              <button type="submit" className="btn modal_save_button">
                Add & Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GroupModalOverlay;
