import React, { useState, useRef, useEffect } from "react";
import "./SelectField.css";
import CustomDropdown from "../custom_dropdown/CustomDropdown";
import { useFormContext } from "../../context/FormContext";

const SelectField = ({ options, forms, handleFormSelect, handleBlurField }) => {
  const { builderType, setBuilderType } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const selectRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setBuilderType("form");
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedIndex = e.target.selectedIndex;
    const selectedForm = forms[selectedIndex - 1];
    handleFormSelect({ ...selectedForm });
  };

  return (
    <>
      <div className="custom-select" ref={selectRef}>
        <div className="custom-select__control" onClick={toggleDropdown}>
          {selectedOption ? selectedOption.label : "Select an option"}
          <span className={`arrow ${isOpen ? "open" : ""}`}>&#9662;</span>
        </div>
        {isOpen && (
          <div className="custom-select__menu">
            {options.map((option) => {
              if (option.value === "create") {
                return (
                  <div
                    key={option.value}
                    className="custom-select__option"
                    data-bs-toggle="modal"
                    data-bs-target="#formModal"
                    onClick={() => {
                      setBuilderType("form");
                      handleOptionClick(option);
                    }}
                  >
                    {option.label}
                  </div>
                );
              } else {
                return (
                  <div
                    key={option.value}
                    className="custom-select__option"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.label}
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
      {selectedOption && selectedOption.value === "existing" ? (
        <>
          <div className="suf_inpfield mt-2">
            <label>Existing Forms:</label>
            <select
              name=""
              id=""
              className="existing_form_select"
              onChange={handleChange}
              onBlur={handleBlurField}
            >
              <option value="" className="existing_form_select_option">
                Select form
              </option>
              {forms.map((form, index) => (
                <option key={index} className="existing_form_select_option">
                  {form.form_builder_name}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SelectField;
