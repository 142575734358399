import React, { useEffect, useState } from "react";
import { useProcessContext } from "../../../context/ProcessContext";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../../config";
import { useAdminContext } from "../../../context/AdminContext";

const SubscriptionForm = () => {
  const { centerAlert, encryptData } = useProcessContext();
  const {
    fetchAllUserData,
    allUsers,
    setAllUsers,
    adminData,
    fetchAllSubscriptionData,
    subscriptionId,
    setsubscriptionId,
  } = useAdminContext();
  const navigate = useNavigate();
  // const [showKey, setShowKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    user_id: "",
    start_date: "",
    expiry_date: "",
    current_status: "",
  });

  // method for getting all users
  const getUsersData = async () => {
    setLoading(true);
    await fetchAllUserData();
    setLoading(false);
  };
  // method for fetching one subscription data
  const handleFetchSubscription = async (id) => {
    const requestBody = {
      subscription_id: id,
      key: API_KEY,
    };
    try {
      const encryptedPayload = encryptData(requestBody, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/subscription/fetch/one`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedPayload }),
      });

      if (response.ok) {
        const data = await response.json();
        const startDate = new Date(data.subscription.start_date)
          .toISOString()
          .slice(0, 16);
        const expiryDate = new Date(data.subscription.expiry_date)
          .toISOString()
          .slice(0, 16);
        console.log("start Date::: ", startDate);
        console.log("expiry Date::: ", expiryDate);

        console.log("Data one fetched successfully:", data);
        setSubscriptionData({
          user_id: data.subscription.user_id,
          start_date: startDate,
          expiry_date: expiryDate,
          current_status: false,
        });
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        console.error("Error creating subscription data:", data);
      }
    } catch (error) {
      setLoading(false);
      centerAlert("error", error);
      console.error("Network error:", error);
    }
  };
  // useEffect For getting subscription update data
  useEffect(() => {
    if (subscriptionId) {
      handleFetchSubscription(subscriptionId);
    }
  }, [subscriptionId]);

  // useEffect for geting all users data
  useEffect(() => {
    getUsersData();
  }, [setAllUsers, adminData.userid]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionData((prevState) => ({
      ...prevState,
      [name]: value?.trim(),
    }));
  };
  //   method for reset form
  const handleResetForm = () =>
    setSubscriptionData({
      user_id: "",
      start_date: "",
      expiry_date: "",
      current_status: "",
    });

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true);
    const requestBody = {
      user_id: subscriptionData.user_id,
      start_date: subscriptionData.start_date,
      expiry_date: subscriptionData.expiry_date,
      key: API_KEY,
    };
    console.log("Submitted Request Data:", requestBody);
    try {
      const encryptedPayload = encryptData(requestBody, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/subscription/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedPayload }),
      });

      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        centerAlert("success", data.message);
        console.log("Data created successfully:", data);
        setsubscriptionId(null);
        await fetchAllSubscriptionData();
        handleResetForm();
        navigate("/admin-dashboard/subscriptions");
      } else {
        const data = await response.json();
        setLoading(false);
        centerAlert("error", data.message);
        console.error("Error creating subscription data:", data);
        // Handle the error here, like showing an error message
      }
    } catch (error) {
      setLoading(false);
      centerAlert("error", error);
      console.error("Network error:", error);
      // Handle network error here
    }
  };

  // Handle form update
  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true);
    const requestBody = {
      subscription_id: subscriptionId,
      user_id: subscriptionData.user_id,
      start_date: subscriptionData.start_date,
      expiry_date: subscriptionData.expiry_date,
      key: API_KEY,
    };

    try {
      const encryptedPayload = encryptData(requestBody, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/subscription/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedPayload }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Data updated successfully:", data);
        setLoading(false);
        centerAlert("success", data.message);
        setsubscriptionId(null);
        await fetchAllSubscriptionData();
        handleResetForm();
        navigate("/admin-dashboard/subscriptions");
      } else {
        const data = await response.json();
        setLoading(false);
        centerAlert("error", data.message);
        console.error("Error creating subscription data:", data);
        // Handle the error here, like showing an error message
      }
    } catch (error) {
      setLoading(false);
      centerAlert("error", error);
      console.error("Network error:", error);
      // Handle network error here
    }
  };

  console.log("subscriptionData::: ", subscriptionData);
  console.log("all users:: ", allUsers);

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
    >
      {/* {loading && (
        <div
          className="progress_bar_checkin"
          style={{ width: "350px", height: "250px", top: "40%" }}
        >
          <h3>Processing...</h3>
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#1a7e8f"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )} */}
      <div className="configure_page_container">
        <form
          className="configure_page_form"
          onSubmit={subscriptionId ? handleUpdate : handleSubmit}
        >
          <h2>Subscription Form</h2>
          <div
            className="mb-3 configure_selection_container d-flex"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleInputUser" className="form-label">
              User
            </label>
            <select
              className="form-select subscription_page_select"
              name="user_id"
              value={subscriptionData.user_id}
              aria-label="select user"
              onChange={handleInputChange}
              disabled={subscriptionId === null ? false : true}
              // style={{ width: "90%", height: "45px" }}
            >
              <option value="">Select user</option>
              {allUsers &&
                allUsers.map((user) => (
                  <option key={user.userid} value={user.userid}>
                    {user.username}
                  </option>
                ))}
            </select>
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleInputStartDate" className="form-label">
              Start Date
            </label>
            <input
              type="datetime-local"
              name="start_date"
              className="form-control"
              value={subscriptionData.start_date}
              placeholder="enter start date"
              id="exampleInputStartDate"
              onChange={handleInputChange}
            />
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleInputExpiryDate" className="form-label">
              Expiry Date
            </label>
            <input
              type="datetime-local"
              name="expiry_date"
              className="form-control"
              value={subscriptionData.expiry_date}
              placeholder="enter expiry date"
              id="exampleInputExpiryDate"
              onChange={handleInputChange}
            />
          </div>
          {/* <div className="d-none mb-3 configure_selection_container">
            <label htmlFor="exampleInputConfigtype1" className="form-label">
              Config Type
            </label>
            <select
              className="form-select configure_page_select"
              name="configType"
              aria-label="Default select example"
              onChange={handleInputChange}
            >
              <option value="">Select type</option>
              <option value="baseUrl">Base Url</option>
            </select>
          </div> */}
          {/* <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%", position: "relative" }}
          >
            <label htmlFor="exampleInputSecurity1" className="form-label">
              Security Key
            </label>
            <input
              type={`${showKey ? "text" : "password"}`}
              name="securitykey"
              value={configureData.securitykey}
              placeholder="enter security key"
              className="form-control"
              id="exampleInputSecurity1"
              onChange={handleInputChange}
            />
            <button
              className="password_eye_button"
              onClick={(e) => {
                e.preventDefault();
                setShowKey(!showKey);
              }}
            >
              {showKey ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div> */}
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Link to={"/admin-dashboard/subscriptions"}>
              <button
                className="btn btn-secondary"
                onClick={() => setsubscriptionId(null)}
              >
                Discard
              </button>
            </Link>
            <button
              type="submit"
              className="btn my-3"
              style={{ backgroundColor: "#1a7e8f", color: "white" }}
            >
              {subscriptionId ? "Update" : "Subscribe"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionForm;
