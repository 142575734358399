import React, { useEffect } from "react";
import { MdDelete, MdEditNote } from "react-icons/md";
import "./DefaultTab.css";
import { useState } from "react";
import DecisionRule from "../../../../template/decision_rule_fields/DecisionRuleFields";
import { TbPointFilled } from "react-icons/tb";
import { GiCancel } from "react-icons/gi";
import { useFormContext } from "../../../../context/FormContext";
import { useNodeContext } from "../../../../context/NodeContext";
import CustomDropdown from "../../../../template/custom_dropdown/CustomDropdown";
import { ImCross } from "react-icons/im";
import { FaDeleteLeft } from "react-icons/fa6";

const DefaultTab = ({
  shapeData,
  setShapeData,
  handleBlurField,
  handleInputChange,
  handleAddDefaultField,
  handleDefaultValueFieldRemove,
}) => {
  const { nodes, setNodes, shapeId, handleFindPrevNode } = useNodeContext();
  const { parseFormData, parseFormDataWithoutTableO2m } = useFormContext();
  const [formFields, setFormFields] = useState(null);
  const [showCustomReferenceInput, setShowCustomReferenceInput] =
    useState(false);
  const [customReferenceData, setCustomReferenceData] = useState({
    digits: 8,
    prefix: "",
    suffix: "",
  });
  const [emailFormFields, setEmailFormFields] = useState(null);
  const [fieldSetUnset, setFieldSetUnset] = useState({
    field: "",
    value: "",
    condition: "",
  });

  const [showDefaultValueInputField, setShowDefaultValueInputField] =
    useState(false);

  const [selectedEmailFields, setSelectedEmailFields] = useState([]);

  const handleReset = () =>
    setFieldSetUnset({
      field: "",
      value: "",
      condition: "",
    });

  const handleFieldSetUnsetConditionDelete = (e) => {
    e.preventDefault();
    setFieldSetUnset((prevData) => ({ ...prevData, condition: "" }));
  };

  // Get selected field type
  const selectedOperand = formFields?.find(
    (field) => field.technicalName === fieldSetUnset.field
  );

  // useEffect for getting form field at same level
  useEffect(() => {
    if (shapeData.form && shapeData.form.formbuilder_data) {
      // const newFields = parseFormData(shapeData.form.formbuilder_data);
      const newFields = parseFormDataWithoutTableO2m(
        shapeData.form.formbuilder_data
      );
      setFormFields([...newFields]);
    }
  }, [shapeData]);

  // useEffect for getting prev form field
  useEffect(() => {
    if (shapeId) {
      const prevNode = handleFindPrevNode(shapeId);
      // console.log("prev node:::: prevNode:: ", prevNode);
      if (!prevNode) {
        setEmailFormFields(null);
      }

      if (prevNode && prevNode.data.form) {
        const newFields = parseFormDataWithoutTableO2m(
          prevNode.data.form.formbuilder_data
        );
        // const newFields = parseFormData(prevNode.data.form.formbuilder_data);
        // console.log("prev node:::: all fields:: ", newFields);

        const newEmails = newFields?.filter(
          (field) => field.widget === "email"
        );
        // console.log("prev node:::: new email field:: ", newEmails);

        setEmailFormFields([...newEmails]);
      }
    }
  }, [shapeId, nodes]);

  // Synchronize selectedEmailFields when emailFormFields or shapeData changes
  useEffect(() => {
    if (emailFormFields && shapeData?.email_verify_fields) {
      const newSelected = emailFormFields
        .filter((field) =>
          shapeData.email_verify_fields.includes(field.technicalName)
        )
        .map((field) => ({
          value: field.technicalName,
          label: field.title,
        }));
      setSelectedEmailFields(newSelected);
    }
  }, [shapeData, emailFormFields, shapeData.email_verify_fields]);

  const handleFieldSetUnsetChange = (e) => {
    const { name, value } = e.target;
    setFieldSetUnset({ ...fieldSetUnset, [name]: value });
  };
  const handleDefaultValueAdd = () => {
    if (
      fieldSetUnset.field !== "" &&
      fieldSetUnset.field !== null &&
      fieldSetUnset !== undefined
    ) {
      handleAddDefaultField({ ...fieldSetUnset });
      handleReset();
    }
  };

  // Render value field conditionally based on the operand type
  const renderValueField = () => {
    if (selectedOperand) {
      if (selectedOperand.type === "date") {
        return (
          <input
            type="date"
            placeholder="select value"
            name="value"
            value={fieldSetUnset.value}
            onChange={handleFieldSetUnsetChange}
            style={{
              width: "85%",
              height: "25px",
              color: "#666666",
              paddingLeft: "10px",
              borderRadius: "5px",
              fontSize: "14px",
            }}
          />
        );
      } else if (selectedOperand.type === "selection") {
        return (
          <CustomDropdown
            name="value"
            defaultValue={fieldSetUnset.value || null}
            options={selectedOperand.options?.map((opt) => {
              return {
                value: `${opt.toLowerCase().trim().replace(/ /g, "_")}`,
                label: opt,
              };
            })}
            onChange={(event) => handleFieldSetUnsetChange(event)}
            width={"85%"}
            height={"25px"}
            border="1px solid gray"
            placeholder="value"
          />
        );
      }
    }

    // Default case: return input type="text" for other operand types
    return (
      <>
        <input
          type="text"
          placeholder="select value"
          name="value"
          value={fieldSetUnset.value}
          onChange={handleFieldSetUnsetChange}
          style={{ width: "85%", fontSize: "14px" }}
        />
      </>
    );
  };

  // method for multi select fields
  const handleEmailFieldSelect = (e) => {
    const field = e.target.value;
    setShapeData((prevData) => ({
      ...prevData,
      email_verify_fields: [...prevData.email_verify_fields, field],
    }));
  };

  const handleRemoveEmailField = (e, fieldToRemove) => {
    e.preventDefault();
    setShapeData((prevData) => ({
      ...prevData,
      email_verify_fields: prevData.email_verify_fields.filter(
        (field) => field !== fieldToRemove
      ),
    }));
  };
  // ===============================
  useEffect(()=>{
    if(shapeData && shapeData.isCustomReferenceNumber === false){
      setShowCustomReferenceInput(false);
    }
  },[shapeData.isCustomReferenceNumber])
  // handling the custom reference number add in node
  const handleCustomReferenceSave = (e) => {
    e.preventDefault();
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                customReferenceNumber: { ...customReferenceData },
              },
            }
          : node
      )
    );
    setShowCustomReferenceInput(false);

    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      customReferenceNumber: { ...customReferenceData },
    }));
    setCustomReferenceData((prevData) => ({
      ...prevData,
      digits: 8,
      prefix: "",
      suffix: "",
    }));
  };

  // handling the custom reference number remove from node
  const handleCustomReferenceRemove = (e) => {
    e.preventDefault();
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === shapeId
          ? {
              ...node,
              data: {
                ...node.data,
                customReferenceNumber: null,
              },
            }
          : node
      )
    );
    setCustomReferenceData((prevData) => ({
      ...prevData,
      digits: 8,
      prefix: "",
      suffix: "",
    }));

    setShapeData((prevShapeData) => ({
      ...prevShapeData,
      customReferenceNumber: null,
    }));
  };

  // method for custom reference data number change
  const handleCustomReferenceData = (e) => {
    const { name, value } = e.target;
    setCustomReferenceData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      {shapeData.type === "email_verify" && (
        // code for email verification field
        <div
          className="d-flex flex-column justify-content-center align-items-start align-self-start gap-1 ps-4"
          style={{ width: "90%" }}
        >
          <label
            htmlFor="email_verify_fields"
            style={{ fontSize: "14px", fontWeight: "500" }}
          >
            Email Verification Field
          </label>
          <div
            className="d-flex justify-content-start align-items-start gap-2"
            style={{ width: "85%", height: "auto" }}
          >
            {selectedEmailFields &&
              selectedEmailFields?.map((field) => (
                <div
                  key={`${field.value}_${field.label}`}
                  className="d-flex justify-content-start align-items-center gap-1 selected_field_tag"
                >
                  <span className="p-1">{field.label}</span>
                  <div
                    className="d-flex flex-wrap justify-content-center align-items-center tag_remove_button"
                    onClick={(e) => handleRemoveEmailField(e, field.value)}
                  >
                    <ImCross />
                  </div>
                </div>
              ))}
          </div>
          <CustomDropdown
            defaultValue={""}
            options={emailFormFields
              ?.filter(
                (fieldData) =>
                  !shapeData?.email_verify_fields?.includes(
                    fieldData.technicalName
                  )
              )
              ?.map((field) => ({
                value: field.technicalName,
                label: field.title,
              }))}
            onChange={(event) => handleEmailFieldSelect(event)}
            onBlur={handleBlurField}
            width={"90%"}
            height={"25px"}
          />
        </div>
      )}
      {/* =============================== */}
      {["start", "webform"].includes(shapeData.type) && (
        <>
          {/* code for webform sequence generator */}
          <div
            className="fb_field_details_form_group_checkbox_container mt-2"
            style={{
              justifyContent: "flex-start",
              gap: "30px",
              alignItems: "center",
            }}
          >
            <label className="checkbox_container">
              <input
                type="checkbox"
                id="fieldIsCustomReferenceNumber"
                name="isCustomReferenceNumber"
                checked={shapeData.isCustomReferenceNumber}
                onChange={handleInputChange}
                onBlur={handleBlurField}
              />
              <span className="checkmark" />
            </label>
            <label
              htmlFor="fieldIsCustomReferenceNumber"
              className="label_checkbox"
            >
              Custom Reference Number
            </label>
          </div>
          {shapeData.isCustomReferenceNumber &&
            shapeData.customReferenceNumber && (
              <div className="selectedForm" style={{ width: "90%" }}>
                <div
                  className="d-flex justify-content-start align-items-start gap-2"
                  style={{
                    width: "80%",
                    overflow: "hidden",
                    wordBreak: "break-all",
                    lineBreak: "anywhere",
                  }}
                >
                  <span>
                    Digits: {shapeData?.customReferenceNumber?.digits},
                  </span>
                  <span>
                    Prefix: {shapeData?.customReferenceNumber?.prefix},
                  </span>
                  <span>
                    Suffix: {shapeData?.customReferenceNumber?.suffix},
                  </span>
                </div>
                <div className="selectedFormBtns">
                  <button
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowCustomReferenceInput(true);
                      setCustomReferenceData({...shapeData.customReferenceNumber})
                    }}
                  >
                    <MdEditNote style={{ fontSize: "25px" }} />
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={handleCustomReferenceRemove}
                  >
                    <FaDeleteLeft style={{ fontSize: "20px" }} />
                  </button>
                </div>
              </div>
            )}
          {!showCustomReferenceInput &&
          shapeData.isCustomReferenceNumber &&
          !shapeData.customReferenceNumber ? (
            <div className="suf_inpfield mt-1">
              <button
                className="btn btn-secondary"
                style={{ fontSize: "14px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowCustomReferenceInput(true);
                }}
              >
                Add Sequence Detail
              </button>
            </div>
          ) : (
            <>
              {showCustomReferenceInput && (
                <div className="suf_inpfield mt-2">
                  <label htmlFor="type">Custome Reference Generate:</label>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="suf_inpfield mt-2">
                      <label htmlFor="digits">Total Digits:</label>
                      <input
                        id="digits"
                        type="number"
                        name="digits"
                        placeholder="Enter total digits"
                        min={2}
                        max={64}
                        style={{ backgroundColor: "#eee" }}
                        value={customReferenceData.digits}
                        onChange={handleCustomReferenceData}
                      />
                    </div>
                    <div className="suf_inpfield mt-2">
                      <label htmlFor="prefix">Prefix:</label>
                      <input
                        id="prefix"
                        type="text"
                        name="prefix"
                        placeholder="Enter prefix"
                        style={{ backgroundColor: "#eee" }}
                        value={customReferenceData.prefix}
                        onChange={handleCustomReferenceData}
                      />
                    </div>
                    <div className="suf_inpfield mt-2">
                      <label htmlFor="suffix">Suffix:</label>
                      <input
                        id="suffix"
                        type="text"
                        name="suffix"
                        placeholder="Enter suffix"
                        style={{ backgroundColor: "#eee" }}
                        value={customReferenceData.suffix}
                        onChange={handleCustomReferenceData}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    {" "}
                    <button
                      className="btn btn-secondary d-flex justify-content-center align-items-center"
                      style={{
                        fontSize: "14px",
                        width: "60px",
                        height: "30px",
                      }}
                      onClick={handleCustomReferenceSave}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-secondary d-flex justify-content-center align-items-center"
                      style={{
                        fontSize: "14px",
                        width: "60px",
                        height: "30px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowCustomReferenceInput(false);
                        setCustomReferenceData((prevData) => ({
                          ...prevData,
                          digits: 8,
                          prefix: "",
                          suffix: "",
                        }));
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
      {/* =============================== */}
      <div className="suf_inpfield mt-1" style={{ width: "90%" }}>
        {/* code for default value checkbox */}
        <div className="fb_field_details_form_group_checkbox_container">
          <label htmlFor="fieldIsDefaultValue" className="label_checkbox">
            Default Value
          </label>
          <label className="checkbox_container">
            <input
              type="checkbox"
              id="fieldIsDefaultValue"
              name="isDefaultValue"
              checked={shapeData.isDefaultValue}
              onChange={handleInputChange}
              onBlur={handleBlurField}
            />
            <span className="checkmark" />
          </label>
        </div>
        {shapeData.isDefaultValue && (
          <>
            {" "}
            {/* code for default value fields */}
            <div style={{ width: "80%" }}>
              {/* code for showing default fields */}
              {shapeData.defaultValue?.set &&
                shapeData.defaultValue?.set?.length > 0 && (
                  <>
                    <details>
                      <summary>Fields</summary>
                      <div
                        className="d-flex flex-column justify-content-start align-items-start gap-2"
                        style={{ width: "100%" }}
                      >
                        {shapeData.defaultValue.set.map((setField, index) => (
                          <div
                            key={`${setField.field}_${index}_${setField.value}`}
                            className="d-flex justify-content-between align-items-start ms-1"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              {index + 1}
                              {"."}
                            </span>
                            <div
                              className="d-flex flex-column justify-content-start align-items-start gap-1"
                              style={{ width: "80%" }}
                            >
                              <div className="d-flex justify-content-start align-items-start gap-2">
                                {" "}
                                <span style={{ fontSize: "14px" }}>
                                  {setField.field}
                                </span>
                                <span style={{ fontSize: "14px" }}>=</span>
                                <span style={{ fontSize: "14px" }}>
                                  {setField.value || `""`}
                                </span>
                              </div>
                              <span
                                style={{
                                  width: "90%",
                                  wordBreak: "break-all",
                                  wordWrap: "break-word",
                                  lineBreak: "anywhere",
                                  lineClamp: "3",
                                  fontSize: "12px",
                                }}
                              >
                                Condition:{" "}
                                {JSON.stringify(setField.condition, null, 2)}
                              </span>
                            </div>
                            <button
                              onClick={(e) =>
                                handleDefaultValueFieldRemove(e, index)
                              }
                              className="condition_delete_button"
                            >
                              <MdDelete />
                            </button>
                          </div>
                        ))}
                      </div>
                    </details>
                  </>
                )}
              {/* code for input box  */}
              {showDefaultValueInputField ? (
                <>
                  <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                    <label htmlFor="">Define Value</label>
                    {fieldSetUnset.condition === "" ||
                    fieldSetUnset.condition === null ||
                    fieldSetUnset.condition === undefined ? (
                      <DecisionRule
                        form={shapeData.form.formbuilder_data}
                        saveRuleData={handleFieldSetUnsetChange}
                        ruleName="condition"
                      />
                    ) : (
                      <div
                        style={{ width: "90%" }}
                        className="ms-3 d-flex justify-content-between align-items-center"
                      >
                        <p className="mb-0">
                          <span>
                            <TbPointFilled />
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              wordBreak: "break-all",
                              lineBreak: "anywhere",
                              wordWrap: "break-word",
                            }}
                          >
                            {fieldSetUnset.condition}
                          </span>
                        </p>
                        <button
                          className="fdu_group_delete"
                          onClick={handleFieldSetUnsetConditionDelete}
                        >
                          <GiCancel />
                        </button>
                      </div>
                    )}
                    <div
                      className="d-flex justify-content-start align-items-start gap-2"
                      style={{ width: "100%" }}
                    >
                      <select
                        name="field"
                        onChange={handleFieldSetUnsetChange}
                        value={fieldSetUnset.field}
                        style={{ width: "85%" }}
                      >
                        <option>Select Field</option>
                        {formFields &&
                          formFields.map((field) => (
                            <option
                              key={field.technicalName}
                              value={field.technicalName}
                            >
                              {field.title}
                            </option>
                          ))}
                      </select>

                      {renderValueField()}
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-start gap-2">
                    <button
                      className="btn btn-secondary mt-2 d-flex justify-content-center align-items-center"
                      onClick={handleDefaultValueAdd}
                      style={{
                        fontSize: "14px",
                        width: "60px",
                        height: "30px",
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-secondary mt-2 d-flex justify-content-center align-items-center"
                      style={{
                        fontSize: "14px",
                        width: "60px",
                        height: "30px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowDefaultValueInputField(false);
                        handleReset();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </>
              ) : Array.isArray(shapeData.defaultValue?.set) &&
                shapeData.defaultValue?.set?.length > 0 ? (
                <div>
                  <button
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowDefaultValueInputField(true);
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    Add More Default Field
                  </button>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-start align-items-start gap-1">
                  <li style={{ fontSize: "14px" }}>No fields available.</li>
                  <button
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowDefaultValueInputField(true);
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    Add Default Value
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DefaultTab;
